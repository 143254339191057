<template>
<div ref="widget_main" id="main_div" class="main_div" v-bind:class="{ 'main_div_im': integration_mode, 'modal-open': modal_info_is_showing || full_modal_active }">

  <div v-if="switch_to_full_modal && full_modal_button && !full_modal_active">

    <div>
      <div
        id="block-layerabgabefrist"
        class="layer-abgabefrist block block-block-content block-block-content424b6aad-57f7-462a-bd56-78301dd867df clearfix"
        style="background-color:transparent;cursor: pointer;"
        @click.prevent="full_modal_active=true" 
      >
        <div class="block-inner">
          <div class="field field--name-body field--type-text-with-summary field--label-hidden field--item" style="text-align:center;">
            {{full_modal_button_html}}
            <div v-if="full_modal_button_html">
              <div v-html="full_modal_button_html"></div>
            </div>
            <div v-else>
              <div style="color:#fff;background-color:#ff0000;font-weight:normal;" class="redbox_div">
				<b v-html="current_full_modal_button_text_a || 'Am 31.10. ist Abgabefrist!'"></b> <span class="handeln_span">{{ full_modal_button_text_m || "Jetzt handeln:" }}&nbsp;</span>
                <a href="#" style="color:#fff;text-decoration:underline;">{{ full_modal_button_text || "Grundsteuererklärung abgeben" }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div v-else>

  <div v-if="modal_info_is_showing || full_modal_active" class="modal-backdrop fade in"></div>
  <div
      class="modal fade"
      :class="{'in': modal_info_is_showing}"
      style="z-index: 999999999;"
      :style="{'display': modal_info_is_showing ? 'block' : 'none'}"
      id="modal_info"
      tabindex="-1"
      role="dialog" aria-labelledby="modal_info_title"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click.prevent="modal_info_is_showing=false" aria-label="Schließen"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="modal_info_title">{{ modal_info_title }}</h4>
        </div>
        <div class="modal-body">
          <p id="modal_info_text" style="text-align: left;" v-html="modal_info_text"></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click.prevent="modal_info_is_showing=false">OK</button>
        </div>
      </div>
    </div>
  </div>

  <div
      :class="{'modal in': full_modal_active}"
      style="display: block;"
      id="full_modal"
      tabindex="-1"
  >
  <div :class="{'modal-dialog modal-lg': full_modal_active}" role="document">
    <div :class="{'modal-content': full_modal_active}">
      <div v-if="full_modal_active" class="modal-header top_header_title_line_modal">
        <button type="button" class="close" @click.prevent="on_close_full_modal" aria-label="Schließen"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="modal_info_title">
			<span v-if="!switch_to_url">
				In drei Schritten zur Grundsteuererklärung
			</span>
			<span v-else>
				{{ switch_to_url_title || "Grundsteuererklärung abgeben" }}
			</span>
		</h4>
      </div>
      <div>

  <ul v-if="!switch_to_url" class="pricing-table">
    <li class="full_box" v-bind:class="{ 'full_box_im': integration_mode }">
      <div
        v-if="!current_mini_mode && (switch_to_full_modal && full_modal_active)"
        id="progress_bar_box"
        :style="{'margin-bottom: 16px;': !full_modal_active}"
      >
        <div
          v-for="(step, index) in progress_steps"
          :key="'progress_step_'+index"
          class="progress_step"
          :class="{'progress_step_active': is_active_progress_step(index)}"
        >
          <div :class="{'progress_step_arrow_left': is_active_progress_step(index)}"></div>
          <div class="progress_step_text">
            <div class="progress_step_text_div">
              <p>{{index+1}}. {{step["text"]}}</p>
            </div>
          </div>
          <div :class="{'progress_step_arrow_right': is_active_progress_step(index)}"></div>
        </div>
      </div>

      <div v-if="curq===qs['bl'] && !full_modal_active">
        <div style="background-color: #42c37a; color: #fff; padding: 16px; margin-bottom: 0px;">
          <h1 v-if="title">{{title}}</h1>
          <h1 v-else>In drei Schritten zur Grundsteuererklärung</h1>
        </div>
      </div>

      <div class="pricing-inner">
        <div class="pricing-content">

          <div v-if="curq===null">
            <p class="question_text">
            </p>
            <div class="question_button_box">
              <button @click="start" class="button">Starten</button>
            </div>
          </div>
          
          <div v-else-if="curq===qs['ergebnis']">
            <div class="row">
              <div class="col-sm-10 col-sm-offset-1">
                <p class="question_text">
                  3. Grundsteuererklärung abgeben
                </p>
              </div>
              <div class="col-sm-1" style="text-align: right;">
                <button @click.prevent="nochmal" class="btn btn-link">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>

            <div v-if="'info_table' in bl_result" style="text-align: center; margin-top: 16px;">
              <div class="row row-no-gutters">
                <div class="col col-sm-6" style="padding-right: 4px;">
                  <div class="alert alert-success" role="alert">
                    <a class="rechner-form-info-button" style="float: right;" @click='on_toggle_info_text("Abgabefrist", "Die Grundsteuererklärung kann ab dem 1. Juli 2022 an das Finanzamt übermittelt werden. Die Abgabefrist endete am " +current_abgabefrist_datum+ ". Bayern hat als einziges Bundesland die Frist bis zum 30. April 2023 verlängert. Bei Fristüberschreitung ist mit Verspätungszuschlägen, Zwangsgeldern bzw. einem Schätzbescheid zu rechnen.")'><span class="glyphicon glyphicon-info-sign" style="font-size: 16px;"></span></a>
                    <span v-if="current_abgabefrist_bl">
                      <span v-html="current_abgabefrist_bl"></span>
                    </span>
                    <span v-else>
                      Abgabefrist:
                      <br />
                      {{current_abgabefrist_datum}}
                    </span>
                  </div>
                </div>
                <div class="col col-sm-6" style="padding-left: 4px;">
                  <div class="alert alert-success" role="alert">
                    <a class="rechner-form-info-button" style="float: right;" @click='on_toggle_info_text("Übermittlungsart", "Die Grundsteuererklärung ist elektronisch an das Finanzamt zu übermitteln. Nur in Härtefällen und auf Antrag ist eine Übermittlung in Papier zulässig. Bayern erlaubt als einziges Bundesland in allen Fällen ohne zusätzlichen Antrag eine Abgabe in Papierform.")'><span class="glyphicon glyphicon-info-sign" style="font-size: 16px;"></span></a>
                    Übermittlung:
                    <br />
                    {{bl_result["info_table"]["uebermittlungsart"]}}
                  </div>
                </div>
              </div>
            </div>

            <div class="row row-no-gutters" style="margin-top: 8px; border-top: 1px; border-bottom: 1px; border-style: solid; border-color: #ddd;">
                <div class="col">
                  <div class="row" style="margin-top: 16px; margin-bottom: 0px; margin-left: 2px; margin-right: 2px;">
                    <div class="col col-xs-10 col-sm-10" style="text-align: left; font-size: 150%; margin-bottom: 4px;">
                      <span>{{anzeige_text}}</span>
                    </div>
                    <div class="col col-xs-2 col-sm-2 text-muted" style="font-size: 85%; text-align: right;">
                      <span>Anzeige</span>
                    </div>
                  </div>
                  <div v-if="widget_html">
                    <div class="alert alert-success alert-rechner" :class="widget_class_str1" :id="widget_id_str1" role="alert">
                      <div v-html="widget_html"></div>
                    </div>
                  </div>
                </div>
                <div class="col" v-if="widget_html2">
                  <div class="text-muted" style="font-size: 85%; text-align: right; margin-bottom: 0;">
                    <span>Anzeige</span>
                  </div>
                  <div class="alert alert-success alert-rechner" :class="widget_class_str2" :id="widget_id_str2" role="alert">
                    <div v-html="widget_html2"></div>
                  </div>
                </div>
                <div class="col" v-if="widget_html3">
                  <div class="text-muted" style="font-size: 85%; text-align: right; margin-bottom: 0;">
                    <span>Anzeige</span>
                  </div>
                  <div class="alert alert-success alert-rechner" :class="widget_class_str3" :id="widget_id_str3" role="alert">
                    <div v-html="widget_html3"></div>
                  </div>
                </div>
              </div>
          </div>
  
          <div v-else-if="curq===qs['bl']">
            <div v-if="!current_mini_mode" class="question_text">
              <p>1. In welchem Bundesland liegt das Grundstück?</p>
            </div>
            
            <div class="row" style="margin: 0;">
              <h3 v-if="current_mini_mode" class="mini_header_small">
                In welchem Bundes&shy;land liegt das Grund&shy;stück?
              </h3>
              <div class="col-lg-3">
                <BLMap @on_bl="on_bl" :mini_mode="true"></BLMap>
              </div>
              <div class="col-lg-9">
                <h3 v-if="current_mini_mode" class="mini_header_large" style="text-align: left; margin-left: 15px;">
                  In welchem Bundes&shy;land liegt das Grund&shy;stück?
                </h3>
                <div class="row mini_row">
                  <div class="col-lg-7">
                    <select
                      type="text"
                      id="bl_select"
                      name="bl_select"
                      v-model="bl"
                      class="form-control mini_mode_select"
                    >
                      <option value="null">— Bundesland wählen —</option>
                      <option
                        v-for="option in bl_names"
                        v-bind:value="option.value"
                        v-bind:key="option.value"
                      >{{ option.text }}</option>
                    </select>
                  </div>
                  <div class="col-lg-5">
                    <button @click='on_bl()' class="button weiter_button weiter_button_mini">Weiter</button>
                  </div>
                </div>
                <p v-if="bl_error" class="question_box_error" style="margin-top: 16px;">
                  Bitte zuerst ein Bundesland auswählen.
                </p>
              </div>
            </div>

            <h4 v-if="curq===qs['bl'] && !current_mini_mode" style="margin: 24px 4px 0 4px;">
              Erfahren Sie auf einen Blick welche Angaben benötigt werden, um die Grundsteuererklärung schnell und einfach zu erledigen.
            </h4>

            <slot></slot>
          </div>

          <div v-else-if="curq===qs['wichtig']">
            <p class="question_text">
              2. Diese Angaben benötigen Sie in {{bl_name}}
            </p>

            <div class="question_box">
              <span style="margin-right: 4px;">
                Auswahl:
              </span>
              <select
                type="text"
                id="bl_select"
                name="bl_select"
                v-model="bl"
                class="form-control small-select"
              >
                <option
                  v-for="option in bl_names"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >{{ option.text }}
                </option>
              </select>
              <select
                v-if="bl_has_gebart"
                style="margin-left: 8px;"
                type="text"
                id="gebart_select"
                name="gebart_select"
                v-model="gebart"
                class="form-control small-select"
              >
                <option
                  v-for="option in gebarts"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >{{ option.text }}
                </option>
              </select>
            </div>
            
            <div v-if="'todo_table' in bl_result">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <td style="width:50%">Angaben</td>
                    <td style="width:50%">Woher</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="row, index in bl_result_filtered"
                    :key="'table_result_'+bl+'_'+index"
                  >
                    <td class="result_table_td" v-html="row['angaben']"></td>
                    <td class="result_table_td">
                      <a v-if="'info' in row" class="rechner-form-info-button" style="float: right;" @click='on_toggle_info_text(row["info"]["title"], row["info"]["text"])'><span class="glyphicon glyphicon-info-sign" style="font-size: 16px;"></span></a>
                      <span v-html="row['unterlagen']"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="question_button_box" style="text-align: right;">
              <button @click="f_wichtig" class="button weiter_button">Weiter</button>
            </div>
          </div>

          <div v-else>
            <p class="question_text">
              Es ist ein unbekannter Fehler aufgetreten, bitte versuchen Sie es nochmal.
            </p>
            <div class="question_button_box">
              <button @click="nochmal" class="button">Nochmal</button>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <ul v-else>
    <HTMLContent :url="switch_to_url" />
  </ul>

      </div>
    </div>
  </div>
  </div>
  
  <img v-if="pixel_utm" :src='"https://widget.grundsteuer.de/pixel.php?cmp=24&ad=91&audience=1&utm_source="+pixel_utm'>
</div>

</div>
</template>

<script>
import axios from 'axios';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.locale('de');
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

import BLMap from './BLMap.vue';
import HTMLContent from '../shared/HTMLContent.vue';

export default {
  name: "Grste3",
  components: {
    BLMap,
    HTMLContent,
  },
  props: {
    // Integration in "Rechner"
    integration_mode: Boolean,

    switch_to_full_modal: Boolean,
    switch_to_url: String,
    switch_to_url_title: String,
    full_modal_button: Boolean,
    full_modal_button_text: String,
    full_modal_button_text_m: String,
    full_modal_button_text_a: String,
    full_modal_button_html: String,

    title: String,
    anzeige_text: String,

    abgabefrist_datum: String,

    // Abgabefristtexte pro Bundesland
    abgabefrist_1: String,
    abgabefrist_2: String,
    abgabefrist_3: String,
    abgabefrist_4: String,
    abgabefrist_5: String,
    abgabefrist_6: String,
    abgabefrist_7: String,
    abgabefrist_8: String,
    abgabefrist_9: String,
    abgabefrist_10: String,
    abgabefrist_11: String,
    abgabefrist_12: String,
    abgabefrist_13: String,
    abgabefrist_14: String,
    abgabefrist_15: String,
    abgabefrist_16: String,

    // Festlegen bei Start
    initial_bl: null,

    // 1. Schritt in kleiner Ansicht?
    mini_mode: null,

    // Basis für den UTM-Parameter
    utm_base: null,

    // Überschreibt Widget Namen
    widget_name: String,
    widget_detail: String,
    no_pixel: Boolean,

    // Ads
    ad_anzahl: null,
    ad_k: null,
    ad_s: null,
    ad_m: null,
  },
  mounted: function() {
	// Layer Abgabefrist Countdown
	if (this.full_modal_button_text_a) {
		// Check if countdown should be generated
		const first_marker_ind = this.full_modal_button_text_a.indexOf("###");
		if (first_marker_ind > -1) {
			const second_marker_ind = this.full_modal_button_text_a.indexOf("###", first_marker_ind+3);
			if (second_marker_ind > -1) {
				// Countdown
				const count_date_str = this.full_modal_button_text_a.substring(first_marker_ind+3, second_marker_ind);
				const count_date = dayjs(count_date_str, 'DD.MM.YYYY', 'de', true);
				if (count_date.isValid()) {
					let countdown_str = this.full_modal_button_text_a.substring(0, first_marker_ind);
					countdown_str += '<span id="gsde_countdown_box" style="color: #000; background-color: rgba(255,255,255,0.6); padding: 4px 10px; margin-left: 4px; margin-right: 4px; border-radius: 2px;">' + count_date.toNow(true) + '</span>';
					countdown_str += this.full_modal_button_text_a.substring(second_marker_ind+3);
					this.current_full_modal_button_text_a = countdown_str;
				}
			}
		}
		else {
			this.current_full_modal_button_text_a = this.full_modal_button_text_a;
		}
	}


    this.current_abgabefrist_datum = this.abgabefrist_datum || "31. Oktober 2022";

    this.current_mini_mode = this.mini_mode;

    this.domain = window.location.hostname;
    this.href = window.location.href;

    const detail_str = this.widget_detail ? "_" + this.widget_detail : "";
    const name_str = this.widget_name ? this.widget_name : "W3";

		this.widget_id_str1 = "GSDE_WIDGET_" + name_str + detail_str + "_K";
		this.widget_id_str2 = "GSDE_WIDGET_" + name_str + detail_str + "_S";
		this.widget_id_str3 = "GSDE_WIDGET_" + name_str + detail_str + "_M";

		this.widget_class_str1 = "GSDE_WIDGET_GROUP_K";
		this.widget_class_str2 = "GSDE_WIDGET_GROUP_S";
		this.widget_class_str3 = "GSDE_WIDGET_GROUP_M";

    // Directly preload Adsense for Cookiebot
    var as_script = document.createElement("script");
    as_script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9660392338473353";
    as_script.crossOrigin = "anonymous";
    as_script.async = true;
    as_script.type = "text/plain";
    as_script.dataset.cookieconsent = "marketing";
    document.head.appendChild(as_script);

    // Priorität 1: URL-Parameter
    // Priorität 2: Tag-Attribut

    let scroll_to_widget = false;
    let params = new URLSearchParams(window.location.search);
    if (params.has("bl")) {
      this.bl_start = parseInt(params.get("bl"));
      if (isNaN(this.bl_start)) {
        this.bl_start = null;
      }
      else {
        scroll_to_widget = true;
      }
    }
    else if (this.initial_bl) {
      this.bl_start = this.initial_bl;
    }

    // Scroll to content content
    if (scroll_to_widget) {
      let element = this.$refs["widget_main"];

      setTimeout(function() {
        let nav_height = 0;
        let nav = document.getElementById("site-header");
        if (nav) {
          nav_height = nav.offsetHeight;
        }
        let offset = element.getBoundingClientRect().top + window.scrollY - nav_height;
        window.scrollTo({"top": offset});
      }, 500);
    }

    const bls = this.bl_names.map(b => b["value"]);
    if (this.bl_start && bls.includes(this.bl_start)) {
      this.bl = this.bl_start;
      this.start_bl();
    }

    // Add Fallbacks
    this.current_ad_anzahl = this.ad_anzahl;
    if (!this.ad_anzahl) {
      this.current_ad_anzahl = 0;
    }
    if (!this.ad_k) {
      this.ad_k="format=1"
    }
    if (!this.ad_s) {
      this.ad_s="format=4"
    }

    if (!this.no_pixel) {
      if (this.utm_base) {
        this.pixel_utm = this.utm_base + name_str;
      }
      else if (this.href == "https://grundsteuer.de/inhalte/lp1.php") {
        this.pixel_utm = "GA_LP1_C_" + name_str;
      }
      else if (this.domain == "grundsteuer.de") {
        this.pixel_utm = "GS_U_C_" + name_str;
      }
      else if (this.domain == "hebesatz.grundsteuer.de") {
        this.pixel_utm = "HS_U_C_" + name_str;
      }
      else {
        this.pixel_utm = "GS_U_C_" + name_str;
      }
    }

    //let adsense_seen = false;
    if (this.ad_k && this.current_ad_anzahl >= 1) {
      if (!this.ad_k.startsWith("_adsense")) {
        let utm = "GS_U_C_" + name_str + detail_str + "_K"
        if (this.utm_base) {
          utm = this.utm_base + name_str + detail_str + "_K";
        }
        else if (this.href == "https://grundsteuer.de/inhalte/lp1.php") {
          utm = "GA_LP1_C_" + name_str + detail_str + "_K"
        }
        else if (this.domain == "grundsteuer.de") {
          utm = "GS_U_C_" + name_str + detail_str + "_K"
        }
        else if (this.domain == "hebesatz.grundsteuer.de") {
          utm = "HS_U_C_" + name_str + detail_str + "_K"
        }
        let url = "https://widget.grundsteuer.de/content.php?"+ this.ad_k + "&utm_source=" + utm;
        let that = this;
        axios
          .get(url)
          .then(function (response) {
            let fixed_html = response.data;
            /*fixed_html = fixed_html.replace(/Anzeige/, "");
            fixed_html = fixed_html.replace(/border: 1px solid #eaeaea; /, "border: 0; ");
            fixed_html = fixed_html.replace(/<div class="col-lg-4">/, '<div class="col-lg-4" style="padding-right: 6px;">')
            fixed_html = fixed_html.replace(/<img /, '<img style="margin-bottom: 16px;" ')
            fixed_html = fixed_html.replace(/class="col-lg-8"><a /, 'class="col-xs-12" style=""><a ');
            fixed_html = fixed_html.replace(/target="_blank"/, 'target="_blank" style="margin-right: 15px;"');
            //fixed_html = fixed_html.replace(/id="link-btn"/, 'id="link-btn" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 200px !important;"');
            fixed_html = fixed_html.replace(/id="link-btn"/, ' class="button primary-button text-white header-button" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 250px !important; margin-top: 40px;"');
            fixed_html = fixed_html.replace(/Jetzt informieren/, 'Jetzt&nbsp;informieren');*/
            that.widget_html = fixed_html;
          })
          .catch(function () {
            that.widget_html = null;
          });
      }
      else {
        let as_id = "8394088977"
        if (this.ad_k != "_adsense") {
          as_id = this.ad_k.substring(9);
        }
        this.widget_html = '<ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-9660392338473353" data-ad-slot="' + as_id + '" data-ad-format="auto" data-full-width-responsive="false"></ins>';
        //adsense_seen = true;
      }
    }

    if (this.ad_s && this.current_ad_anzahl >= 2) {
      if (!this.ad_s.startsWith("_adsense")) {
        let utm2 = "GS_U_C_" + name_str + detail_str + "_S";
        if (this.utm_base) {
          utm2 = this.utm_base + name_str + detail_str + "_S";
        }
        else if (this.href == "https://grundsteuer.de/inhalte/lp1.php") {
          utm2 = "GA_LP1_C_" + name_str + detail_str + "_S";
        }
        else if (this.domain == "grundsteuer.de") {
          utm2 = "GS_U_C_" + name_str + detail_str + "_S";
        }
        else if (this.domain == "hebesatz.grundsteuer.de") {
          utm2 = "HS_U_C_" + name_str + detail_str + "_S";
        }
        let url2 = "https://widget.grundsteuer.de/content.php?" + this.ad_s + "&utm_source=" + utm2;
        let that = this;
        axios
          .get(url2)
          .then(function (response) {
            let fixed_html = response.data;
            /*fixed_html = fixed_html.replace(/Anzeige/, "");
            fixed_html = fixed_html.replace(/border: 1px solid #eaeaea; /, "border: 0; ");
            fixed_html = fixed_html.replace(/<div class="col-lg-4">/, '<div class="col-lg-4" style="padding-right: 6px;">')
            fixed_html = fixed_html.replace(/<img /, '<img style="margin-bottom: 16px;" ')
            fixed_html = fixed_html.replace(/class="col-lg-8"><a /, 'class="col-xs-12" style=""><a ');
            fixed_html = fixed_html.replace(/target="_blank"/, 'target="_blank" style="margin-right: 15px;"');
            //fixed_html = fixed_html.replace(/id="link-btn"/, 'id="link-btn" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 200px !important;"');
            fixed_html = fixed_html.replace(/id="link-btn"/, ' class="button primary-button text-white header-button" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 250px !important; margin-top: 40px;"');
            fixed_html = fixed_html.replace(/Jetzt informieren/, 'Jetzt&nbsp;informieren');*/
            that.widget_html2 = fixed_html;
          })
          .catch(function () {
            that.widget_html2 = null;
          });
      }
      else {
        let as_id = "8394088977"
        if (this.ad_s != "_adsense") {
          as_id = this.ad_s.substring(9);
        }
        this.widget_html2 = '<ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-9660392338473353" data-ad-slot="' + as_id + '" data-ad-format="auto" data-full-width-responsive="false"></ins>';
        //adsense_seen = true;
      }
    }

    if (this.ad_m && this.current_ad_anzahl >= 3) {
      if (!this.ad_m.startsWith("_adsense")) {
        let utm3 = "GS_U_C_" + name_str + detail_str + "_M";
        if (this.utm_base) {
          utm3 = this.utm_base + name_str + detail_str + "_M";
        }
        else if (this.href == "https://grundsteuer.de/inhalte/lp1.php") {
          utm3 = "GA_LP1_C_" + name_str + detail_str + "_M";
        }
        else if (this.domain == "grundsteuer.de") {
          utm3 = "GS_U_C_" + name_str + detail_str + "_M";
        }
        else if (this.domain == "hebesatz.grundsteuer.de") {
          utm3 = "HS_U_C_" + name_str + detail_str + "_M";
        }
        let url3 = "https://widget.grundsteuer.de/content.php?" + this.ad_m + "&utm_source=" + utm3;
        let that = this;
        axios
          .get(url3)
          .then(function (response) {
            let fixed_html = response.data;
            /*fixed_html = fixed_html.replace(/Anzeige/, "");
            fixed_html = fixed_html.replace(/border: 1px solid #eaeaea; /, "border: 0; ");
            fixed_html = fixed_html.replace(/<div class="col-lg-4">/, '<div class="col-lg-4" style="padding-right: 6px;">')
            fixed_html = fixed_html.replace(/<img /, '<img style="margin-bottom: 16px;" ')
            fixed_html = fixed_html.replace(/class="col-lg-8"><a /, 'class="col-xs-12" style=""><a ');
            fixed_html = fixed_html.replace(/target="_blank"/, 'target="_blank" style="margin-right: 15px;"');
            //fixed_html = fixed_html.replace(/id="link-btn"/, 'id="link-btn" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 200px !important;"');
            fixed_html = fixed_html.replace(/id="link-btn"/, ' class="button primary-button text-white header-button" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 250px !important; margin-top: 40px;"');
            fixed_html = fixed_html.replace(/Jetzt informieren/, 'Jetzt&nbsp;informieren');*/
            that.widget_html3 = fixed_html;
          })
          .catch(function () {
            that.widget_html3 = null;
          });
      }
      else {
        let as_id = "8394088977"
        if (this.ad_m != "_adsense") {
          as_id = this.ad_m.substring(9);
        }
        this.widget_html3 = '<ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-9660392338473353" data-ad-slot="' + as_id + '" data-ad-format="auto" data-full-width-responsive="false"></ins>';
        //adsense_seen = true;
      }
    }

    //if (adsense_seen) {
      let that = this;
      let times = 10;
      this.interval_id = window.setInterval(function() {
        if (that.seen_page3) {
          if (times <= 0) {
            window.clearInterval(that.interval_id);
          }
          else {
            times -= 1;
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          }
        }
      }, 1000)
    //}
  },
  unmounted: function() {
    /*if (this.interval_id) {
      window.clearInterval(this.interval_id);
    }*/
  },
  data: function() {
    return {
      domain: null,
      href: null,

      current_mini_mode: false,
      current_abgabefrist_datum: null,

      widget_id_str1: null,
      widget_id_str2: null,
      widget_id_str3: null,

      widget_class_str1: null,
      widget_class_str2: null,
      widget_class_str3: null,

      // Aktuelle Frage
      curq: 1,

      // Betrachtete Fragen
      qstack: [ 1 ],

      // Fragen
      qs: {
        "bl": 1,
        "wichtig": 2,
        "ergebnis": 3
      },

      //
      seen_page3: false,
      interval_id: null,
      pixel_utm: null,
      current_ad_anzahl: null,
      widget_html: null,
      widget_html2: null,
      widget_html3: null,

      // Progressbar Steps
      progress_steps: [
        {
          "text": "Bundesland",
        },
        {
          "text": "Angaben",
        },
        {
          "text": "Grundsteuererklärung",
        }
      ],

      // Frage 1: Bundesland
      bl_start: null,
      bl: null,
      bl_error: null,
      bl_names: [
        { value: 8, text: "Baden-Württemberg" },
        { value: 9, text: "Bayern" },
        { value: 11, text: "Berlin" },
        { value: 12, text: "Brandenburg" },
        { value: 4, text: "Bremen" },
        { value: 2, text: "Hamburg" },
        { value: 6, text: "Hessen" },
        { value: 13, text: "Mecklenburg-Vorpommern" },
        { value: 3, text: "Niedersachsen" },
        { value: 5, text: "Nordrhein-Westfalen" },
        { value: 7, text: "Rheinland-Pfalz" },
        { value: 10, text: "Saarland" },
        { value: 14, text: "Sachsen" },
        { value: 15, text: "Sachsen-Anhalt" },
        { value: 1, text: "Schleswig-Holstein" },
        { value: 16, text: "Thüringen" }
      ],

      // Bundesländer mit Gebäudeart-Anzeige
      bl_gebarts: [
        11,
        12,
        4,
        13,
        5,
        7,
        10,
        14,
        15,
        1,
        16
      ],

      // Gebäudearten
      gebart: "w_100",
      gebarts: [
        { value: "w_100", text: "Wohngrundstücke" },
        { value: "w_101", text: "— Einfamilienhaus" },
        { value: "w_102", text: "— Zweifamilienhaus" },
        { value: "w_103", text: "— Mietwohngrundstück" },
        { value: "w_104", text: "— Wohnungseigentum" },
        { value: "a_200", text: "Nichtwohngrundstücke" },
        { value: "a_201", text: "— Teileigentum" },
        { value: "a_202", text: "— Geschäftsgrundstücke" },
        { value: "a_203", text: "— Gemischt genutzte Grundstücke" },
        { value: "a_204", text: "— Sonstige bebaute Grundstücke" },
      ],

      // Info-Text
      modal_info_title: '',
      modal_info_text: '',
      modal_info_is_showing: false,

      // Widget is in "full modal" mode
      full_modal_active: false,
      current_full_modal_button_text_a: null,

      // Ergebnisse pro BL
      results: {
        // Baden-Württemberg
        8: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
            },
            "todo_table": [
            {
              "angaben": 'Aktenzeichen',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Aktenzeichen',
                "text": 'Das Aktenzeichen können Sie dem Informationsschreiben entnehmen, das im Mai und Juni 2022 an alle Immobilieneigentümer in Baden-Württemberg versendet wurde.<br />'+
                  '<br />'+
                  'Alternativen:'+
                  '- Einheitswertbescheid des Finanzamts'+
                  '- Grundsteuerbescheid der Gemeinde'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig bei der Gemeinde schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grundstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grundstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Informationen finden Sie im Informationsschreiben. Alternativ finden sich die Angaben im Grundbuchauszug.'
              }
            },
            {
              "angaben": 'Bodenrichtwert',
              "unterlagen":'BORIS-BW',
              "info": {
                "title": 'Bodenrichtwert',
                "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie online über "BORIS-BW".'
              }
            },
          ]
        },
        // Berlin
        11: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": "Berlin versendet keine Informationsschreiben"
          },
          "todo_table": [
            {
              "angaben": 'Steuernummer',
              "unterlagen":'Einheitswertbescheid',
              "info": {
                "title": 'Steuernummer',
                "text": 'Die Steuernummer können Sie alternativ dem Grundsteuerbescheid der Stadt Berlin entnehmen.'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grundstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grundstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Informationen finden Sie im Grundbuchauszug oder über das Liegenschaftskataster.'
              }
            },
            {
              "angaben": 'Bodenrichtwert',
              "unterlagen":'BORIS Berlin',
              "info": {
                "title": 'Bodenrichtwert',
                "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie online über "BORIS Berlin".'
              }
            },
            {
              "angaben": 'Baujahr',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Baujahr',
                "text": 'Das Baujahr lässt sich i.d.R. den Bauunterlagen entnehmen. Alternativ ist das Baujahr teilweise in Kaufverträgen genannt.'
              }
            },
            {
              "art": 'w',
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Kaufvertrag oder Mietvertrag<br />'+
                  '- Teilungserklärung<br />'+
                  '- Wohnflächenberechnung<br />'
              }
            },
            {
              "art": 'w',
              "angaben": 'Anzahl der Garagenstellplätze',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Anzahl der Garagenstellplätze',
                "text": 'Ist die genaue Anzahl der Garagenstellplätze nicht bekannt, finden Sie diese Angaben in den Bauunterlagen oder teilweise auch in Kaufverträgen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Gebäudeart',
              "unterlagen":'Anlage 42 zum BewG',
              "info": {
                "title": 'Gebäudeart',
                "text": 'Die Gebäudeart ist nach Anlage 42 zum Bewertungsgesetz zu ermitteln. Fällt das Gebäude unter keine der dort genannten Gebäudearten ist eine vergleichbare Gebäudeart auszuwählen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Bruttogrundfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Bruttogrundfläche',
                "text": 'Die Bruttogrundfläche ergibt sich i.d.R. aus den Bauunterlagen. Alternativ kann die Bruttogrundfläche selbst ermittelt werden.'
              }
            },
          ]
        },
        // Brandenburg
        12: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
           {
              "angaben": 'Aktenzeichen',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Aktenzeichen',
                "text": 'Das Aktenzeichen können Sie dem Informationsschreiben entnehmen, das im April 2022 an alle Immobilieneigentümer in Brandenburg versendet wurde.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Einheitswertbescheid des Finanzamts<br />'+
                  '- Grundsteuerbescheid der Gemeinde<br />'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grundstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grundstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Informationsportal Grundstücksdaten',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Speziell für die Grundsteuerreform können Sie diese Angaben über das sog. "Informationsportal Grundstücksdaten" online abrufen.'
              }
            },
            {
              "angaben": 'Bodenrichtwert',
              "unterlagen":'Informationsportal Grundstücksdaten',
              "info": {
                "title": 'Bodenrichtwert',
                "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie online über das "Informationsportal Grundstücksdaten" des Landes Brandenburg.'
              }
            },
            {
              "angaben": 'Baujahr',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Baujahr',
                "text": 'Das Baujahr lässt sich i.d.R. den Bauunterlagen entnehmen. Alternativ ist das Baujahr teilweise in Kaufverträgen genannt.'
              }
            },
            {
              "art": 'w',
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Kaufvertrag oder Mietvertrag<br />'+
                  '- Teilungserklärung<br />'+
                  '- Wohnflächenberechnung<br />'
              }
            },
            {
              "art": 'w',
              "angaben": 'Anzahl der Garagenstellplätze',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Anzahl der Garagenstellplätze',
                "text": 'Ist die genaue Anzahl der Garagenstellplätze nicht bekannt, finden Sie diese Angaben in den Bauunterlagen oder teilweise auch in Kaufverträgen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Gebäudeart',
              "unterlagen":'Anlage 42 zum BewG',
              "info": {
                "title": 'Gebäudeart',
                "text": 'Die Gebäudeart ist nach Anlage 42 zum Bewertungsgesetz zu ermitteln. Fällt das Gebäude unter keine der dort genannten Gebäudearten ist eine vergleichbare Gebäudeart auszuwählen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Bruttogrundfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Bruttogrundfläche',
                "text": 'Die Bruttogrundfläche ergibt sich i.d.R. aus den Bauunterlagen. Alternativ kann die Bruttogrundfläche selbst ermittelt werden.'
              }
            },
          ],
        },
        // Bremen
        4: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
              "angaben": 'Steuernummer',
              "unterlagen":'Einheitswertbescheid',
              "info": {
                "title": 'Steuernummer',
                "text": 'Die Steuernummer können Sie alternativ dem Grundsteuerbescheid der Stadt Bremen oder Bremenhaven entnehmen. Die Steuernummer wird auch in dem Informationsschreiben genannt, das im Juli 2022 an alle Immobilieneigentümer versendet wird.'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grundstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grundstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Flurstücksviewer',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Speziell für die Grundsteuerreform können Sie diese Angaben über den sog. "Flurstücksviewer" online abrufen.'
              }
            },
            {
              "angaben": 'Bodenrichtwert',
              "unterlagen":'BORIS',
              "info": {
                "title": 'Bodenrichtwert',
                "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie online über "BORIS".'
              }
            },
            {
              "angaben": 'Baujahr',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Baujahr',
                "text": 'Das Baujahr lässt sich i.d.R. den Bauunterlagen entnehmen. Alternativ ist das Baujahr teilweise in Kaufverträgen genannt.'
              }
            },
            {
              "art": 'w',
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Kaufvertrag oder Mietvertrag<br />'+
                  '- Teilungserklärung<br />'+
                  '- Wohnflächenberechnung<br />'
              }
            },
            {
              "art": 'w',
              "angaben": 'Anzahl der Garagenstellplätze',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Anzahl der Garagenstellplätze',
                "text": 'Ist die genaue Anzahl der Garagenstellplätze nicht bekannt, finden Sie diese Angaben in den Bauunterlagen oder teilweise auch in Kaufverträgen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Gebäudeart',
              "unterlagen":'Anlage 42 zum BewG',
              "info": {
                "title": 'Gebäudeart',
                "text": 'Die Gebäudeart ist nach Anlage 42 zum Bewertungsgesetz zu ermitteln. Fällt das Gebäude unter keine der dort genannten Gebäudearten ist eine vergleichbare Gebäudeart auszuwählen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Bruttogrundfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Bruttogrundfläche',
                "text": 'Die Bruttogrundfläche ergibt sich i.d.R. aus den Bauunterlagen. Alternativ kann die Bruttogrundfläche selbst ermittelt werden.'
              }
            },
          ],
        },
        // Hamburg
        2: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
              "angaben": 'Steuernummer',
              "unterlagen":'Einheitswertbescheid',
              "info": {
                "title": 'Steuernummer',
                "text": 'Die Steuernummer können Sie alternativ dem Grundsteuerbescheid der Stadt Hamburg entnehmen.'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grunstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grunstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Informationen finden Sie über das sog. "Geoportal (Liegenschaftskataster)" online.'
              }
            },
            {
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Kaufvertrag oder Mietvertrag<br />'+
                  '- Teilungserklärung<br />'+
                  '- Wohnflächenberechnung<br />'
              }
            },
          ],
        },
        // Hessen
        6: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
              "angaben": 'Aktenzeichen',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Aktenzeichen',
                "text": 'Das Aktenzeichen können Sie alternativ dem Grundsteuerbescheid der Gemeinde entnehmen.'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grunstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grunstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Informationen finden Sie über die Flurstückssuche auf "Geodaten online".'
              }
            },
            {
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Kaufvertrag oder Mietvertrag<br />'+
                  '- Teilungserklärung<br />'+
                  '- Wohnflächenberechnung<br />'
              }
            },
          ]
        },
        // Mecklenburg-Vorpommern
        13: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
              "angaben": 'Aktenzeichen',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Aktenzeichen',
                "text": 'Das Aktenzeichen können Sie dem Informationsschreiben entnehmen, das im Mai 2022 an alle Immobilieneigentümer in Mecklenburg-Vorpommern versendet wurde.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Einheitswertbescheid des Finanzamts<br />'+
                  '- Grundsteuerbescheid der Gemeinde<br />'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grundstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grundstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Grundsteuerdaten M-V',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Speziell für die Grundsteuerreform können Sie diese Angaben über das Portal "Grundsteuerdaten M-V" online abrufen.'
              }
            },
            {
              "angaben": 'Bodenrichtwert',
              "unterlagen":'Grundsteuerdaten M-V',
              "info": {
                "title": 'Bodenrichtwert',
                "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie online über das Portal "Grundsteuerdaten M-V" des Landes Mecklenburg-Vorpommern.'
              }
            },
            {
              "angaben": 'Baujahr',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Baujahr',
                "text": 'Das Baujahr lässt sich i.d.R. den Bauunterlagen entnehmen. Alternativ ist das Baujahr teilweise in Kaufverträgen genannt.'
              }
            },
            {
              "art": 'w',
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Kaufvertrag oder Mietvertrag<br />'+
                  '- Teilungserklärung<br />'+
                  '- Wohnflächenberechnung<br />'
              }
            },
            {
              "art": 'w',
              "angaben": 'Anzahl der Garagenstellplätze',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Anzahl der Garagenstellplätze',
                "text": 'Ist die genaue Anzahl der Garagenstellplätze nicht bekannt, finden Sie diese Angaben in den Bauunterlagen oder teilweise auch in Kaufverträgen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Gebäudeart',
              "unterlagen":'Anlage 42 zum BewG',
              "info": {
                "title": 'Gebäudeart',
                "text": 'Die Gebäudeart ist nach Anlage 42 zum Bewertungsgesetz zu ermitteln. Fällt das Gebäude unter keine der dort genannten Gebäudearten ist eine vergleichbare Gebäudeart auszuwählen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Bruttogrundfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Bruttogrundfläche',
                "text": 'Die Bruttogrundfläche ergibt sich i.d.R. aus den Bauunterlagen. Alternativ kann die Bruttogrundfläche selbst ermittelt werden.'
              }
            },
          ],
        },
        // Niedersachsen
        3: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
              "angaben": 'Aktenzeichen',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Aktenzeichen',
                "text": 'Das Aktenzeichen können Sie alternativ dem Grundsteuerbescheid der Gemeinde entnehmen.'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grunstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grunstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Grundsteuer-Viewer',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Informationen finden Sie über den sog. "Grundsteuer-Viewer" online.'
              }
            },
            {
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Kaufvertrag oder Mietvertrag<br />'+
                  '- Teilungserklärung<br />'+
                  '- Wohnflächenberechnung<br />'
              }
            },
          ]
        },
        // Nordrhein-Westfalen
        5: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
           {
              "angaben": 'Aktenzeichen',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Aktenzeichen',
                "text": 'Das Aktenzeichen können Sie dem Informationsschreiben entnehmen, das im Mai 2022 an alle Immobilieneigentümer in Nordrhein-Westfalen versendet wurde.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Einheitswertbescheid des Finanzamts<br />'+
                  '- Grundsteuerbescheid der Gemeinde<br />'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grundstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grundstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Grundsteuerportal',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Angaben können Sie über das sog. "Grundsteuerportal" online abrufen.'
              }
            },
            {
              "angaben": 'Bodenrichtwert',
              "unterlagen":'Grundsteuerportal',
              "info": {
                "title": 'Bodenrichtwert',
                "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie online über das sog. "Grundsteuerportal" des Landes Nordrhein-Westfalen.'
              }
            },
            {
              "angaben": 'Baujahr',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Baujahr',
                "text": 'Das Baujahr lässt sich i.d.R. den Bauunterlagen entnehmen. Alternativ ist das Baujahr teilweise in Kaufverträgen genannt.'
              }
            },
            {
              "art": 'w',
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Kaufvertrag oder Mietvertrag<br />'+
                  '- Teilungserklärung<br />'+
                  '- Wohnflächenberechnung<br />'
              }
            },
            {
              "art": 'w',
              "angaben": 'Anzahl der Garagenstellplätze',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Anzahl der Garagenstellplätze',
                "text": 'Ist die genaue Anzahl der Garagenstellplätze nicht bekannt, finden Sie diese Angaben in den Bauunterlagen oder teilweise auch in Kaufverträgen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Gebäudeart',
              "unterlagen":'Anlage 42 zum BewG',
              "info": {
                "title": 'Gebäudeart',
                "text": 'Die Gebäudeart ist nach Anlage 42 zum Bewertungsgesetz zu ermitteln. Fällt das Gebäude unter keine der dort genannten Gebäudearten ist eine vergleichbare Gebäudeart auszuwählen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Bruttogrundfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Bruttogrundfläche',
                "text": 'Die Bruttogrundfläche ergibt sich i.d.R. aus den Bauunterlagen. Alternativ kann die Bruttogrundfläche selbst ermittelt werden.'
              }
            },

          ],
        },
        // Rheinland-Pfalz
        7: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
              "angaben": 'Aktenzeichen',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Aktenzeichen',
                "text": 'Das Aktenzeichen können Sie dem Informationsschreiben entnehmen, das von Mai bis August 2022 an alle Immobilieneigentümer in Rheinland-Pfalz versendet wird.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Einheitswertbescheid des Finanzamts<br />'+
                  '- Grundsteuerbescheid der Gemeinde<br />'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grundstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grundstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Datenblatt (Informationsschreiben)',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Angaben erhalten Sie über das dem Informationsschreiben beigefügte Datenblatt. Alternativ finden Sie diese Angaben auch im Grundbuchauszug.'
              }
            },
            {
              "angaben": 'Bodenrichtwert',
              "unterlagen":'Datenblatt (Informationsschreiben)',
              "info": {
                "title": 'Bodenrichtwert',
                "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie im Datenblatt das dem Informationsschreiben beigefügt ist. Alternativ ist der Bodenrichtwert über "BORIS" online abrufbar.'
              }
            },
            {
              "angaben": 'Baujahr',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Baujahr',
                "text": 'Das Baujahr lässt sich i.d.R. den Bauunterlagen entnehmen. Alternativ ist das Baujahr teilweise in Kaufverträgen genannt.'
              }
            },
            {
              "art": 'w',
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Kaufvertrag oder Mietvertrag<br />'+
                  '- Teilungserklärung<br />'+
                  '- Wohnflächenberechnung<br />'
              }
            },
            {
              "art": 'w',
              "angaben": 'Anzahl der Garagenstellplätze',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Anzahl der Garagenstellplätze',
                "text": 'Ist die genaue Anzahl der Garagenstellplätze nicht bekannt, finden Sie diese Angaben in den Bauunterlagen oder teilweise auch in Kaufverträgen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Gebäudeart',
              "unterlagen":'Anlage 42 zum BewG',
              "info": {
                "title": 'Gebäudeart',
                "text": 'Die Gebäudeart ist nach Anlage 42 zum Bewertungsgesetz zu ermitteln. Fällt das Gebäude unter keine der dort genannten Gebäudearten ist eine vergleichbare Gebäudeart auszuwählen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Bruttogrundfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Bruttogrundfläche',
                "text": 'Die Bruttogrundfläche ergibt sich i.d.R. aus den Bauunterlagen. Alternativ kann die Bruttogrundfläche selbst ermittelt werden.'
              }
            },

          ],
        },
        // Saarland
        10: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
              "angaben": 'Aktenzeichen',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Aktenzeichen',
                "text": 'Das Aktenzeichen können Sie dem Informationsschreiben entnehmen, das im Juni 2022 an alle Immobilieneigentümer im Saarland versendet wurde.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Einheitswertbescheid des Finanzamts<br />'+
                  '- Grundsteuerbescheid der Gemeinde<br />'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grundstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grundstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Angaben können Sie dem Grundbuchauszug entnehmen.'
              }
            },
            {
              "angaben": 'Bodenrichtwert',
              "unterlagen":'Geoportal Saarland',
              "info": {
                "title": 'Bodenrichtwert',
                "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie online über das sog. "Grundsteuerportal" des Landes Nordrhein-Westfalen.'
              }
            },
            {
              "angaben": 'Baujahr',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Baujahr',
                "text": 'Das Baujahr lässt sich i.d.R. den Bauunterlagen entnehmen. Alternativ ist das Baujahr teilweise in Kaufverträgen genannt.'
              }
            },
            {
              "art": 'w',
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Kaufvertrag oder Mietvertrag<br />'+
                  '- Teilungserklärung<br />'+
                  '- Wohnflächenberechnung<br />'
              }
            },
            {
              "art": '2',
              "angaben": 'Anzahl der Garagenstellplätze',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Anzahl der Garagenstellplätze',
                "text": 'Ist die genaue Anzahl der Garagenstellplätze nicht bekannt, finden Sie diese Angaben in den Bauunterlagen oder teilweise auch in Kaufverträgen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Gebäudeart',
              "unterlagen":'Anlage 42 zum BewG',
              "info": {
                "title": 'Gebäudeart',
                "text": 'Die Gebäudeart ist nach Anlage 42 zum Bewertungsgesetz zu ermitteln. Fällt das Gebäude unter keine der dort genannten Gebäudearten ist eine vergleichbare Gebäudeart auszuwählen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Bruttogrundfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Bruttogrundfläche',
                "text": 'Die Bruttogrundfläche ergibt sich i.d.R. aus den Bauunterlagen. Alternativ kann die Bruttogrundfläche selbst ermittelt werden.'
              }
            },

          ],
        },
        // Sachsen
        14: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
              "angaben": 'Aktenzeichen',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Aktenzeichen',
                "text": 'Das Aktenzeichen können Sie dem Informationsschreiben entnehmen, das im Juni 2022 an alle Immobilieneigentümer in Sachsen versendet wurde.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Einheitswertbescheid des Finanzamts<br />'+
                  '- Grundsteuerbescheid der Gemeinde<br />'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grundstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grundstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'Grundsteuerportal Sachsen',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Angaben können Sie über das sog. "Grundsteuerportal Sachsen" online abrufen.'
              }
            },
            {
              "angaben": 'Bodenrichtwert',
              "unterlagen":'Grundsteuerportal Sachsen',
              "info": {
                "title": 'Bodenrichtwert',
                "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie online über das sog. "Grundsteuerportal Sachsen".'
              }
            },
            {
              "angaben": 'Baujahr',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Baujahr',
                "text": 'Das Baujahr lässt sich i.d.R. den Bauunterlagen entnehmen. Alternativ ist das Baujahr teilweise in Kaufverträgen genannt.'
              }
            },
            {
              "art": 'w',
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Kaufvertrag oder Mietvertrag<br />'+
                  '- Teilungserklärung<br />'+
                  '- Wohnflächenberechnung<br />'
              }
            },
            {
              "art": 'w',
              "angaben": 'Anzahl der Garagenstellplätze',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Anzahl der Garagenstellplätze',
                "text": 'Ist die genaue Anzahl der Garagenstellplätze nicht bekannt, finden Sie diese Angaben in den Bauunterlagen oder teilweise auch in Kaufverträgen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Gebäudeart',
              "unterlagen":'Anlage 42 zum BewG',
              "info": {
                "title": 'Gebäudeart',
                "text": 'Die Gebäudeart ist nach Anlage 42 zum Bewertungsgesetz zu ermitteln. Fällt das Gebäude unter keine der dort genannten Gebäudearten ist eine vergleichbare Gebäudeart auszuwählen.'
              }
            },
            {
              "art": 'a',
              "angaben": 'Bruttogrundfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Bruttogrundfläche',
                "text": 'Die Bruttogrundfläche ergibt sich i.d.R. aus den Bauunterlagen. Alternativ kann die Bruttogrundfläche selbst ermittelt werden.'
              }
            },
          ],
        },
        // Sachsen-Anhalt
        15: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
                "angaben": 'Aktenzeichen',
                "unterlagen":'Informationsschreiben',
                "info": {
                  "title": 'Aktenzeichen',
                  "text": 'Das Aktenzeichen können Sie dem Informationsschreiben entnehmen, das im Juni 2022 an alle Immobilieneigentümer in Sachsen-Anhalt versendet wurde.<br />'+
                    '<br />'+
                    'Alternativen:<br />'+
                    '- Einheitswertbescheid des Finanzamts<br />'+
                    '- Grundsteuerbescheid der Gemeinde<br />'
                }
              },
              {
                "angaben": 'Eigentumsverhältnisse',
                "unterlagen":'Grundbuchauszug',
                "info": {
                  "title": 'Eigentumsverhältnisse',
                  "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
                }
              },
              {
                "angaben": 'Lage des Grundstücks',
                "unterlagen":'Adresse',
                "info": {
                  "title": 'Lage des Grundstücks',
                  "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
                }
              },
              {
                "angaben": 'Angaben zum Grund und Boden',
                "unterlagen":'Grundsteuer-Viewer',
                "info": {
                  "title": 'Grundsteuer-Viewer',
                  "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Angaben können Sie über den sog. "Grundsteuer-Viewer" online abrufen.'
                }
              },
              {
                "angaben": 'Bodenrichtwert',
                "unterlagen":'Grundsteuer-Viewer',
                "info": {
                  "title": 'Bodenrichtwert',
                  "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie online über den sog. "Grundsteuer-Viewer".'
                }
              },
              {
                "angaben": 'Baujahr',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Baujahr',
                  "text": 'Das Baujahr lässt sich i.d.R. den Bauunterlagen entnehmen. Alternativ ist das Baujahr teilweise in Kaufverträgen genannt.'
                }
              },
              {
                "art": 'w',
                "angaben": 'Wohn- und Nutzfläche',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Wohn- und Nutzfläche',
                  "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                    '<br />'+
                    'Alternativen:<br />'+
                    '- Kaufvertrag oder Mietvertrag<br />'+
                    '- Teilungserklärung<br />'+
                    '- Wohnflächenberechnung<br />'
                }
              },
              {
                "art": 'w',
                "angaben": 'Anzahl der Garagenstellplätze',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Anzahl der Garagenstellplätze',
                  "text": 'Ist die genaue Anzahl der Garagenstellplätze nicht bekannt, finden Sie diese Angaben in den Bauunterlagen oder teilweise auch in Kaufverträgen.'
                }
              },
              {
                "art": 'a',
                "angaben": 'Gebäudeart',
                "unterlagen":'Anlage 42 zum BewG',
                "info": {
                  "title": 'Gebäudeart',
                  "text": 'Die Gebäudeart ist nach Anlage 42 zum Bewertungsgesetz zu ermitteln. Fällt das Gebäude unter keine der dort genannten Gebäudearten ist eine vergleichbare Gebäudeart auszuwählen.'
                }
              },
              {
                "art": 'a',
                "angaben": 'Bruttogrundfläche',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Bruttogrundfläche',
                  "text": 'Die Bruttogrundfläche ergibt sich i.d.R. aus den Bauunterlagen. Alternativ kann die Bruttogrundfläche selbst ermittelt werden.'
                }
              },
          ],
        },
        // Schleswig-Holstein
        1: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
                "angaben": 'Aktenzeichen',
                "unterlagen":'Informationsschreiben',
                "info": {
                  "title": 'Aktenzeichen',
                  "text": 'Das Aktenzeichen können Sie dem Informationsschreiben entnehmen, das im Juni 2022 an alle Immobilieneigentümer in Schleswig-Holstein versendet wurde.<br />'+
                    '<br />'+
                    'Alternativen:<br />'+
                    '- Einheitswertbescheid des Finanzamts<br />'+
                    '- Grundsteuerbescheid der Gemeinde<br />'
                }
              },
              {
                "angaben": 'Eigentumsverhältnisse',
                "unterlagen":'Grundbuchauszug',
                "info": {
                  "title": 'Eigentumsverhältnisse',
                  "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
                }
              },
              {
                "angaben": 'Lage des Grundstücks',
                "unterlagen":'Adresse',
                "info": {
                  "title": 'Lage des Grundstücks',
                  "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
                }
              },
              {
                "angaben": 'Angaben zum Grund und Boden',
                "unterlagen":'Grundsteuerportal',
                "info": {
                  "title": 'Angaben zum Grund und Boden',
                  "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Angaben können Sie über das sog. "Grundsteuerportal" des Landes Schleswig-Holstein online abrufen.'
                }
              },
              {
                "angaben": 'Bodenrichtwert',
                "unterlagen":'Grundsteuerportal',
                "info": {
                  "title": 'Bodenrichtwert',
                  "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie online über das sog. "Grundsteuerportal" des Landes Schleswig-Holstein.'
                }
              },
              {
                "angaben": 'Baujahr',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Baujahr',
                  "text": 'Das Baujahr lässt sich i.d.R. den Bauunterlagen entnehmen. Alternativ ist das Baujahr teilweise in Kaufverträgen genannt.'
                }
              },
              {
                "art": 'w',
                "angaben": 'Wohn- und Nutzfläche',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Wohn- und Nutzfläche',
                  "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                    '<br />'+
                    'Alternativen:<br />'+
                    '- Kaufvertrag oder Mietvertrag<br />'+
                    '- Teilungserklärung<br />'+
                    '- Wohnflächenberechnung<br />'
                }
              },
              {
                "art": 'w',
                "angaben": 'Anzahl der Garagenstellplätze',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Anzahl der Garagenstellplätze',
                  "text": 'Ist die genaue Anzahl der Garagenstellplätze nicht bekannt, finden Sie diese Angaben in den Bauunterlagen oder teilweise auch in Kaufverträgen.'
                }
              },
              {
                "art": 'a',
                "angaben": 'Gebäudeart',
                "unterlagen":'Anlage 42 zum BewG',
                "info": {
                  "title": 'Gebäudeart',
                  "text": 'Die Gebäudeart ist nach Anlage 42 zum Bewertungsgesetz zu ermitteln. Fällt das Gebäude unter keine der dort genannten Gebäudearten ist eine vergleichbare Gebäudeart auszuwählen.'
                }
              },
              {
                "art": 'a',
                "angaben": 'Bruttogrundfläche',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Bruttogrundfläche',
                  "text": 'Die Bruttogrundfläche ergibt sich i.d.R. aus den Bauunterlagen. Alternativ kann die Bruttogrundfläche selbst ermittelt werden.'
                }
              },
          ],
        },
        // Thüringen
        16: {
          "info_table": {
            "uebermittlungsart": "elektronisch, in Härtefällen in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
                "angaben": 'Aktenzeichen',
                "unterlagen":'Informationsschreiben',
                "info": {
                  "title": 'Aktenzeichen',
                  "text": 'Das Aktenzeichen können Sie dem Informationsschreiben entnehmen, das im April 2022 an alle Immobilieneigentümer in Thüringen versendet wurde. <br />'+
                    '<br />'+
                    'Alternativen:<br />'+
                    '- Einheitswertbescheid des Finanzamts<br />'+
                    '- Grundsteuerbescheid der Gemeinde<br />'
                }
              },
              {
                "angaben": 'Eigentumsverhältnisse',
                "unterlagen":'Grundbuchauszug',
                "info": {
                  "title": 'Eigentumsverhältnisse',
                  "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
                }
              },
              {
                "angaben": 'Lage des Grundstücks',
                "unterlagen":'Adresse',
                "info": {
                  "title": 'Lage des Grundstücks',
                  "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
                }
              },
              {
                "angaben": 'Angaben zum Grund und Boden',
                "unterlagen":'Grundsteuer-Viewer',
                "info": {
                  "title": 'Angaben zum Grund und Boden',
                  "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Angaben können Sie über den sog. "Grundsteuer-Viewer" online abrufen.'
                }
              },
              {
                "angaben": 'Bodenrichtwert',
                "unterlagen":'Grundsteuer-Viewer',
                "info": {
                  "title": 'Bodenrichtwert',
                  "text": 'Den Bodenrichtwert zum Stichtag 1. Januar 2022 finden Sie online über den sog. "Grundsteuer-Viewer".'
                }
              },
              {
                "angaben": 'Baujahr',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Baujahr',
                  "text": 'Das Baujahr lässt sich i.d.R. den Bauunterlagen entnehmen. Alternativ ist das Baujahr teilweise in Kaufverträgen genannt.'
                }
              },
              {
                "art": 'w',
                "angaben": 'Wohn- und Nutzfläche',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Wohn- und Nutzfläche',
                  "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                    '<br />'+
                    'Alternativen:<br />'+
                    '- Kaufvertrag oder Mietvertrag<br />'+
                    '- Teilungserklärung<br />'+
                    '- Wohnflächenberechnung<br />'
                }
              },
              {
                "art": 'w',
                "angaben": 'Anzahl der Garagenstellplätze',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Anzahl der Garagenstellplätze',
                  "text": 'Ist die genaue Anzahl der Garagenstellplätze nicht bekannt, finden Sie diese Angaben in den Bauunterlagen oder teilweise auch in Kaufverträgen.'
                }
              },
              {
                "art": 'a',
                "angaben": 'Gebäudeart',
                "unterlagen":'Anlage 42 zum BewG',
                "info": {
                  "title": 'Gebäudeart',
                  "text": 'Die Gebäudeart ist nach Anlage 42 zum Bewertungsgesetz zu ermitteln. Fällt das Gebäude unter keine der dort genannten Gebäudearten ist eine vergleichbare Gebäudeart auszuwählen.'
                }
              },
              {
                "art": 'a',
                "angaben": 'Bruttogrundfläche',
                "unterlagen":'Bauunterlagen',
                "info": {
                  "title": 'Bruttogrundfläche',
                  "text": 'Die Bruttogrundfläche ergibt sich i.d.R. aus den Bauunterlagen. Alternativ kann die Bruttogrundfläche selbst ermittelt werden.'
                }
              },
          ],
        },
        // Bayern
        9: {
          "info_table": {
            "uebermittlungsart": "elektronisch oder in Papier",
            "infoschreiben": ""
          },
          "todo_table": [
            {
              "angaben": 'Aktenzeichen',
              "unterlagen":'Informationsschreiben',
              "info": {
                "title": 'Aktenzeichen',
                "text": 'Das Aktenzeichen können Sie dem Informationsschreiben entnehmen, das im April 2022 an alle Immobilieneigentümer in Bayern versendet wurde.<br />'+
                  '<br />'+
                  'Alternativen:<br />'+
                  '- Einheitswertbescheid des Finanzamts<br />'+
                  '- Grundsteuerbescheid der Gemeinde<br />'
              }
            },
            {
              "angaben": 'Eigentumsverhältnisse',
              "unterlagen":'Grundbuchauszug',
              "info": {
                "title": 'Eigentumsverhältnisse',
                "text": 'Die Eigentumsverhältnisse können Sie dem Grundbuchauszug entnehmen. Ein aktueller Grundbuchauszug kann kostenpflichtig beim Grundbuchamt schriftlich beantragt werden.'
              }
            },
            {
              "angaben": 'Lage des Grundstücks',
              "unterlagen":'Adresse',
              "info": {
                "title": 'Lage des Grundstücks',
                "text": 'Ist die genaue Lagebezeichnung des Grundstücks nicht bekannt, können Sie diese dem letzten Einheitswertbescheid entnehmen.'
              }
            },
            {
              "angaben": 'Angaben zum Grund und Boden',
              "unterlagen":'BayernAtlas',
              "info": {
                "title": 'Angaben zum Grund und Boden',
                "text": 'Zu den Angaben zum Grund und Boden gehören die Gemarkung, Flur und Flurstück. Diese Informationen finden Sie über den sog. "BayernAtlas" online.'
              }
            },
            {
              "angaben": 'Wohn- und Nutzfläche',
              "unterlagen":'Bauunterlagen',
              "info": {
                "title": 'Wohn- und Nutzfläche',
                "text": 'Die Wohn- und Nutzfläche können Sie i.d.R. den Bauunterlagen entnehmen.<br />'+
                '<br />'+
                'Alternativen:<br />'+
                '- Kaufvertrag oder Mietvertrag<br />'+
                '- Teilungserklärung<br />'+
                '- Wohnflächenberechnung<br />'
              }
            },
          ]
        }
      }
    };
  },
  computed: {
    current_abgabefrist_bl: function() {
      // Unschön, aber macht Eingabe leichter und schöner
      if (this.bl == 1) {
        return this.abgabefrist_1;
      }
      else if (this.bl == 2) {
        return this.abgabefrist_2;
      }
      else if (this.bl == 3) {
        return this.abgabefrist_3;
      }
      else if (this.bl == 4) {
        return this.abgabefrist_4;
      }
      else if (this.bl == 5) {
        return this.abgabefrist_5;
      }
      else if (this.bl == 6) {
        return this.abgabefrist_6;
      }
      else if (this.bl == 7) {
        return this.abgabefrist_7;
      }
      else if (this.bl == 8) {
        return this.abgabefrist_8;
      }
      else if (this.bl == 9) {
        return this.abgabefrist_9;
      }
      else if (this.bl == 10) {
        return this.abgabefrist_10;
      }
      else if (this.bl == 11) {
        return this.abgabefrist_11;
      }
      else if (this.bl == 12) {
        return this.abgabefrist_12;
      }
      else if (this.bl == 13) {
        return this.abgabefrist_13;
      }
      else if (this.bl == 14) {
        return this.abgabefrist_14;
      }
      else if (this.bl == 15) {
        return this.abgabefrist_15;
      }
      else if (this.bl == 16) {
        return this.abgabefrist_16;
      }

      return null;
    },

    bl_result: function() {
      if (this.bl in this.results) {
        return this.results[this.bl];
      }
      return {}
    },

    bl_result_filtered: function() {
      // Wenn eine Gebäudeart-Kategorie angegeben ist, muss sie zur ausgewählten passen
      return this.bl_result['todo_table'].filter(row => 
        !("art" in row) || row["art"] == this.art
      )
    },

    bl_name: function() {
      for (const blo of this.bl_names) {
        if (blo["value"] === this.bl) {
          return blo["text"];
        }
      }
      return "";
    },

    bl_has_gebart: function() {
      return this.bl_gebarts.includes(this.bl);
    },

    art: function() {
      if (this.gebart) {
        return this.gebart[0];
      }
      return "";
    }
  },
  watch: {
    curq: function(new_val, old_val) {
      // Speichern dass sich die Frage geändert hat
      // Aber nur, wenn es eine neuere Frage ist
      if (new_val > old_val) {
        this.qstack.push(new_val);
      }
    }
  },
  methods: {
    is_active_progress_step: function(index) {
      const val = this.curq-1;
      if (val >= 0 && val < this.progress_steps.length) {
        return val === index;
      }
      return false;
    },

    on_toggle_info_text: function(title, text) {
      this.modal_info_title = title;
      this.modal_info_text = text;
      this.modal_info_is_showing = true;
    },

    start: function() {
      const start_id = this.qs['bl'];
      this.qstack = [ start_id ];
      this.curq = start_id;
    },

    start_bl: function() {
      const start_id = this.qs['wichtig'];
      this.qstack = [ start_id ];
      this.curq = start_id;
    },

    nochmal: function() {
      this.$emit('on_nochmal');

      this.bl = null;

      this.start();
    },
    
    f_zurueck: function() {
      // Gehts zurück?
      // Der aktuelle Schritt ist ebenfalls in der Liste
      if (this.qstack.length > 1) {
        // Entfernen des aktuellen
        this.qstack.pop();
        
        // Den vorherigen holen
        const last_q = this.qstack[this.qstack.length-1];
        
        // Zur Frage springen
        this.curq = last_q;
      }
    },

    on_close_full_modal: function() {
      this.start();
      this.full_modal_active = false;
    },

    on_bl: function(data) {
      if (data && "bl" in data) {
        this.bl = data.bl;
      }

      if (this.bl) {
        this.bl_error = false;
        this.curq = this.qs['wichtig'];

        if (this.switch_to_full_modal) {
          this.full_modal_active = true;
        }

        let event_label = "W3_1";
        if (this.current_mini_mode) {
          event_label = "W3_1_A";
        }

        // Always disable mini mode in step 2
        this.current_mini_mode = false;

        if (window.ga) {
          window.ga('send', 'event', 'W3', 'Klick', event_label);
        }
        if (window._paq) {
          window._paq.push(['trackEvent', 'W3', 'Klick', event_label]);
        }
      }
      else {
        this.bl_error = true;
      }
    },

    f_wichtig: function() {
      this.curq = this.qs['ergebnis'];
      this.seen_page3 = true;

      if (window.ga) {
        window.ga('send', 'event', 'W3', 'Klick', 'W3_2');
      }
      if (window._paq) {
        window._paq.push(['trackEvent', 'W3', 'Klick', 'W3_2']);
      }
    },

  }
};
</script>

<style scoped lang="scss">
#gsde_bl_area_map a:hover path {
  fill: #f0ad4e !important;
}

.main_div {
  margin: 0 auto;
  text-align: center;
}

.main_div_im {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  text-align: center !important;
}

.full_box {
  width: 100%;
}

.full_box_im {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.question_text {
  font-size: 150%;
  font-weight: bold;
}

.pricing-table {
  margin: 0 !important;
}

.pricing-table > li {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pricing-inner::before {
  top: 0px !important;
  bottom: 0px !important;
  border: 0 !important;
}

.pricing-inner:hover::before {
  border: 0 !important;
  border-color: transparent !important;
}

.pricing-inner {
  //padding: 60px 40px 90px 40px;
  padding: 16px 40px 90px 40px !important;
}

@media only screen and (max-width: 767px) {
  .pricing-inner {
    padding: 16px 2px 90px 2px !important;
  }
}

.pricing-title h3 {
  text-transform: none !important;
  font-weight: normal !important;
}

.pricing-content {
  padding-top: 20px !important;
}

.question_box {
  margin: 16px 0 !important;
}

.question_box select {
  height: 40px !important;
  padding: 5px 15px !important;
}

.question_box .small-select {
  display: inline-block;
  width: inherit;
  width: 35%;
}

.question_box_error {
  color: #a94442 !important;
}

.question_button_box {
  margin-top: 24px !important;
}

.question_back_button_box button {
  margin-top: 8px !important;
}

.question_back_button_box button {
  background-color: transparent !important;
  border: 0 !important;
}

.question_back_button_box button:hover {
  background-color: transparent !important;
  border: 2px solid #1f2a30 !important;
  color: #1f2a30 !important;
  transition-duration: 100ms !important;
}

.question_box_buttons {
  margin: 16px 0 !important;
}

.question_box_buttons button p {
  margin-top: -4px !important;
}

.question_box_buttons_small button p {
  margin-top: -24px !important;
}

.question_box_buttons button {
  height: 180px !important;
  width: 200px !important;
  margin: 16px 16px !important;
}

.question_box_buttons button svg {
  vertical-align: middle !important;
}

.question_box_buttons_small button {
  height: 150px !important;
  width: 180px !important;
  margin: 16px 16px !important;
}

.question_box_buttons_images {
  margin: 16px 0 !important;
  border-top: 0;
}

.question_box_buttons_images button {
  width: 250px !important;
  height: 100% !important;
  margin: 16px 16px !important;
  background-color: #fff !important;
  border-color: transparent !important;
  border-radius: 24px;
}

.question_box_buttons_images button:hover {
  background-color: #f1f1f1 !important;
  border-color: transparent !important;
}

.question_box_buttons_images button img {
  margin: 4px auto !important;
}

.question_box_range input {
  accent-color: #218ACB !important;
}

#baujahr_progressbar {
  height: 10px;
  width: 0;
  background-color: #218ACB;
}

#progress_bar_box {
  width: 100%;
  background-color: #218ACB;
  color: #fff;
  border: 2px solid #218ACB;
}

#progress_bar_box .progress_step {
  display: inline-block;
  padding: 0 24px 0 24px;
  height: 32px;
  //width: 33%;
}

@media only screen and (max-width: 767px) {
  #progress_bar_box .progress_step {
    width: inherit;
  }
}

#progress_bar_box .progress_step_active {
  background-color: #fff;
  color: #218ACB;
  font-weight: bold;
  padding: 0;
}

.progress_step_arrow_left {
  margin-right: 16px;
  background-color: #fff;
  float: left;
  width: 0; 
  height: 0; 
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #218ACB;
}

.progress_step_arrow_right {
  margin-left: 16px;
  background-color: #218ACB;
  float: right;
  width: 0; 
  height: 0; 
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #fff;
}

.progress_step_text {
  display: inline-block;
  height: 32px;
}

.progress_step_text_div {
  height: 32px;
  margin-top: 4px;
}

.ergebnis_box {
  border: dotted 2px black;
}

.ergebnis_title h4 {
  font-weight: bold;
  font-size: 125%;
}

table {
  //background-color: #218ACB;
  //color: #fff;
  border-radius: 16px;
  margin-bottom: -24px;
  overflow-wrap: anywhere;
}

table > thead > tr > td {
  color: #fff;
  border-color: #218ACB;
  background-color: #218ACB;
  padding: 8px !important;
}

table > tbody > tr > td {
  border-color: #ddd;
  padding: 8px !important;
}

.result_table_td {
  text-align: left;
}

.result_table_td > a {
  //color: #fff !important;
  text-decoration: underline !important;
}

.rechner-form-info-button {
  //color: #ccc;
  margin: 0;
  font-size: 20px;
  line-height: 0;
}

// TODO farben
.alert-rechner {
  background-color: #ddd;
  color: #000;
  border-color: #ddd;
}

/*.header-button {
  background-color: #fa7d19;
  transition: none !important;
  font-weight: bold;
  font-variant: normal !important;
  font-variant-caps: normal !important;
  text-transform: none;
  font-size: 17px !important;
  line-height: 24px;
}

.header-button:hover {
  border: 0;
  transition: none !important;
  border-radius: 0 !important;
}*/


.weiter_button {
  background-color: #42c37a;
  color: #fff;
}


@media only screen and (max-width: 767px) {
  .weiter_button {
    margin-right: 16px;
  }
}

.weiter_button:hover {
  border-color: #42c37a !important;
  background-color: #46CF81 !important;
}

.mini_mode_select {
  margin: 0 auto;
  margin-top: 5px;
  height: 40px;
}

.weiter_button_mini {
  width: 100%;
  background-color: #218acb;
  border: 0;
}
.weiter_button_mini:hover {
  border-color: #218acb !important;
  background-color: #1f2930 !important;
}

.mini_row {
  margin: 0;
  margin-top: 36px;
}

.mini_text {
  margin: 0 8px -16px 8px;
}

.mini_header_small {
  display: none;
}

.mini_header_large {
  display: block;
}

@media only screen and (max-width: 1199px) {
  .mini_mode_select {
    margin-top: 8px !important;
    margin-bottom: 16px !important;
  }

  .mini_row {
    margin-top: 0;
  }

  .mini_text {
    margin: 0 8px 4px 8px;
  }

  .mini_header_small {
    display: block;
  }

  .mini_header_large {
    display: none;
  }
}

.glyphicon-info-sign {
  cursor: pointer;
}

#full_modal .modal-content {
  border: 0 !important;
}

.top_header_title_line_modal {
  background-color: #42c37a;
  border-color: #42c37a;
  color: #fff;
}

.gsde_full_modal_active_button {
  width: inherit;
  padding: 8px 16px;
  color: #fefefe;
  background-color: #218acb;
  border: 1px solid #efefef;
}

.gsde_full_modal_active_button:hover {
  color: #fff;
  background-color: #1f2930;
  border-color: #218acb;
}

.redbox_div {
	padding: 4px;
}

.handeln_span { 
	display: none;
}

@media only screen and (min-width: 738px) {
	.redbox_div {
		font-size:16px;
		padding: 12px;
	}

	.handeln_span { 
		display: inline-block;
	}
}

</style>
