<template>
	<div>
		<div v-if="html_content">
			<div v-html="html_content"></div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: "HTMLContent",
	props: {
		url: String,
	},
	data: function() {
		return {
			html_content: null,
		}
	},
	mounted: function() {
		console.log("loading url", this.url)
		let that = this;
		axios
			.get(this.url)
			.then(function (response) {
				let fixed_html = response.data;
				//fixed_html = fixed_html.replace(/Anzeige/, "");
				that.html_content = fixed_html;
			})
			.catch(function () {
				that.html_content = null;
		});
	},
}
</script>

<style scoped lang="scss">
</style>
