<template>
<div class="row row-no-gutters" style="margin-top: 8px; border-top: 1px; border-bottom: 1px; border-style: solid; border-color: #ddd;">
	<div class="col">
		<div class="row" style="margin-top: 16px; margin-bottom: 0px; margin-left: 2px; margin-right: 2px;">
			<div class="col col-xs-10 col-sm-10" style="text-align: left; font-size: 150%; margin-bottom: 4px;">
				<span v-html="anzeige_text"></span>
			</div>
			<div class="col col-xs-2 col-sm-2 text-muted" style="font-size: 85%; text-align: right;">
				<span>Anzeige</span>
			</div>
		</div>
		<div v-if="widget_html">
			<div class="alert  alert-rechner" :class="widget_class_str1" :id="widget_id_str1" role="alert">
				<div v-html="widget_html"></div>
			</div>
		</div>
	</div>
	<div class="col" v-if="widget_html2">
		<div class="text-muted" style="font-size: 85%; text-align: right; margin-bottom: 0;">
			<span>Anzeige</span>
		</div>
		<div class="alert alert-rechner" :class="widget_class_str2" :id="widget_id_str2" role="alert">
			<div v-html="widget_html2"></div>
		</div>
	</div>
	<div class="col" v-if="widget_html3">
		<div class="text-muted" style="font-size: 85%; text-align: right; margin-bottom: 0;">
			<span>Anzeige</span>
		</div>
		<div class="alert alert-rechner" :class="widget_class_str3" :id="widget_id_str3" role="alert">
			<div v-html="widget_html3"></div>
		</div>
	</div>
</div>
</template>


<script>
import axios from 'axios';

export default {
	name: "AdBoxes",
	props: {
		widget_name: String,
		widget_detail: String,
		anzeige_text: String,

		utm_base: String,
		utm_ext: String,

		ad_anzahl: Number,
		ad_k: String,
		ad_s: String,
		ad_m: String,
	},
	data: function() {
	return {
		widget_html: null,
		widget_html2: null,
		widget_html3: null,

		widget_id_str1: null,
		widget_id_str2: null,
		widget_id_str3: null,

		widget_class_str1: null,
		widget_class_str2: null,
		widget_class_str3: null,

		current_ad_anzahl: 0,
	}
	},
	mounted: function() {
		const domain = window.location.hostname;

	// Add Fallbacks
		this.current_ad_anzahl = this.ad_anzahl;
		if (!this.ad_anzahl || !this.ad_m) {
			this.current_ad_anzahl = 2;
		}

	let that = this;
	//let adsense_seen = false;

		const detail_str = this.widget_detail ? "_" + this.widget_detail : "";
		const utm_ext_str = this.utm_ext ? this.utm_ext : "";

		this.widget_id_str1 = "GSDE_WIDGET_" + this.widget_name + detail_str + "_K";
		this.widget_id_str2 = "GSDE_WIDGET_" + this.widget_name + detail_str + "_S";
		this.widget_id_str3 = "GSDE_WIDGET_" + this.widget_name + detail_str + "_M";

		this.widget_class_str1 = "GSDE_WIDGET_GROUP_K";
		this.widget_class_str2 = "GSDE_WIDGET_GROUP_S";
		this.widget_class_str3 = "GSDE_WIDGET_GROUP_M";

		if (this.ad_k && this.current_ad_anzahl >= 1) {
		if (!this.ad_k.startsWith("_adsense")) {
			let utm = "GS_U_C_" + this.widget_name + detail_str + "_K"
			if (this.utm_base) {
				utm = this.utm_base + this.widget_name + detail_str + "_K";
			}
			else if (domain == "grundsteuer.de") {
				utm = "GS_U_C_" + this.widget_name + detail_str + "_K"
			}
			else if (domain == "hebesatz.grundsteuer.de") {
				utm = "HS_U_C_" + this.widget_name + detail_str + "_K"
			}
			utm += utm_ext_str;
			let url = "https://widget.grundsteuer.de/content.php?"+ this.ad_k + "&utm_source=" + utm;
			axios
				.get(url)
				.then(function (response) {
					let fixed_html = response.data;
					/*fixed_html = fixed_html.replace(/Anzeige/, "");
					fixed_html = fixed_html.replace(/border: 1px solid #eaeaea; /, "border: 0; ");
					fixed_html = fixed_html.replace(/<div class="col-lg-4">/, '<div class="col-lg-4" style="padding-right: 6px;">')
					fixed_html = fixed_html.replace(/<img /, '<img style="margin-bottom: 16px;" ')
					fixed_html = fixed_html.replace(/class="col-lg-8"><a /, 'class="col-xs-12" style=""><a ');
					fixed_html = fixed_html.replace(/target="_blank"/, 'target="_blank" style="margin-right: 15px;"');
					//fixed_html = fixed_html.replace(/id="link-btn"/, 'id="link-btn" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 200px !important;"');
					fixed_html = fixed_html.replace(/id="link-btn"/, ' class="button primary-button text-white header-button" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 250px !important; margin-top: 40px;"');
					fixed_html = fixed_html.replace(/Jetzt informieren/, 'Jetzt&nbsp;informieren');*/
					that.widget_html = fixed_html;
				})
				.catch(function () {
					that.widget_html = null;
				});
		}
		else {
			let as_id = this.ad_k.substring(9);
			this.widget_html = '<ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-9660392338473353" data-ad-slot="' + as_id + '" data-ad-format="auto" data-full-width-responsive="false"></ins>';
			//adsense_seen = true;
		}
	}

	if (this.ad_s && this.current_ad_anzahl >= 2) {
			if (!this.ad_s.startsWith("_adsense")) {
		let utm2 = "GS_U_C_" + this.widget_name + detail_str + "_S"
				if (this.utm_base) {
					utm2 = this.utm_base + this.widget_name + detail_str + "_S"
				}
				else if (domain == "grundsteuer.de") {
					utm2 = "GS_U_C_" + this.widget_name + detail_str + "_S"
				}
				else if (domain == "hebesatz.grundsteuer.de") {
					utm2 = "HS_U_C_" + this.widget_name + detail_str + "_S"
				}
				utm2 += utm_ext_str;
				let url2 = "https://widget.grundsteuer.de/content.php?" + this.ad_s + "&utm_source=" + utm2;
				axios
					.get(url2)
					.then(function (response) {
						let fixed_html = response.data;
						/*fixed_html = fixed_html.replace(/Anzeige/, "");
						fixed_html = fixed_html.replace(/border: 1px solid #eaeaea; /, "border: 0; ");
						fixed_html = fixed_html.replace(/<div class="col-lg-4">/, '<div class="col-lg-4" style="padding-right: 6px;">')
						fixed_html = fixed_html.replace(/<img /, '<img style="margin-bottom: 16px;" ')
						fixed_html = fixed_html.replace(/class="col-lg-8"><a /, 'class="col-xs-12" style=""><a ');
						fixed_html = fixed_html.replace(/target="_blank"/, 'target="_blank" style="margin-right: 15px;"');
						//fixed_html = fixed_html.replace(/id="link-btn"/, 'id="link-btn" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 200px !important;"');
						fixed_html = fixed_html.replace(/id="link-btn"/, ' class="button primary-button text-white header-button" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 250px !important; margin-top: 40px;"');
						fixed_html = fixed_html.replace(/Jetzt informieren/, 'Jetzt&nbsp;informieren');*/
						that.widget_html2 = fixed_html;
					})
					.catch(function () {
						that.widget_html2 = null;
					});
			}
			else {
				let as_id = this.ad_s.substring(9);
				this.widget_html2 = '<ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-9660392338473353" data-ad-slot="' + as_id + '" data-ad-format="auto" data-full-width-responsive="false"></ins>';
				//adsense_seen = true;
			}
		}

		if (this.ad_m && this.current_ad_anzahl >= 3) {
			if (!this.ad_m.startsWith("_adsense")) {
		let utm3 = "GS_U_C_" + this.widget_name + detail_str + "_M"
				if (this.utm_base) {
					utm3 = this.utm_base + this.widget_name + detail_str + "_M"
				}
				else if (domain == "grundsteuer.de") {
					utm3 = "GS_U_C_" + this.widget_name + detail_str + "_M"
				}
				else if (domain == "hebesatz.grundsteuer.de") {
					utm3 = "HS_U_C_" + this.widget_name + detail_str + "_M"
				}
				utm3 += utm_ext_str;
				let url3 = "https://widget.grundsteuer.de/content.php?" + this.ad_m + "&utm_source=" + utm3;
				axios
					.get(url3)
					.then(function (response) {
						let fixed_html = response.data;
						/*fixed_html = fixed_html.replace(/Anzeige/, "");
						fixed_html = fixed_html.replace(/border: 1px solid #eaeaea; /, "border: 0; ");
						fixed_html = fixed_html.replace(/<div class="col-lg-4">/, '<div class="col-lg-4" style="padding-right: 6px;">')
						fixed_html = fixed_html.replace(/<img /, '<img style="margin-bottom: 16px;" ')
						fixed_html = fixed_html.replace(/class="col-lg-8"><a /, 'class="col-xs-12" style=""><a ');
						fixed_html = fixed_html.replace(/target="_blank"/, 'target="_blank" style="margin-right: 15px;"');
						//fixed_html = fixed_html.replace(/id="link-btn"/, 'id="link-btn" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 200px !important;"');
						fixed_html = fixed_html.replace(/id="link-btn"/, ' class="button primary-button text-white header-button" style="background-color: #fa7d19 !important; border-radius: 0 !important; width: 250px !important; margin-top: 40px;"');
						fixed_html = fixed_html.replace(/Jetzt informieren/, 'Jetzt&nbsp;informieren');*/
						that.widget_html3 = fixed_html;
					})
					.catch(function () {
						that.widget_html3 = null;
					});
			}
			else {
				let as_id = this.ad_m.substring(9);
				this.widget_html3 = '<ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-9660392338473353" data-ad-slot="' + as_id + '" data-ad-format="auto" data-full-width-responsive="false"></ins>';
				//adsense_seen = true;
			}
		}

	//if (adsense_seen) {
			//let that = this;
			let times = 10;
			this.interval_id = window.setInterval(function() {
		if (times <= 0) {
		window.clearInterval(that.interval_id);
		}
		else {
		times -= 1;
		(window.adsbygoogle = window.adsbygoogle || []).push({});
		}
			}, 1000)
		//}
	},
	unmounted: function() {
		if (this.interval_id) {
			window.clearInterval(this.interval_id);
		}
	},
}
</script>

<style scoped lang="scss">
.alert-rechner {
	background-color: #ddd;
	color: #000;
	border-color: #ddd;
}
</style>