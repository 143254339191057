export const FormulareHessen = [
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_1_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_1_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_2_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_2_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_3_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_3_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_4_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_4_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_5_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_5_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_6_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_6_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_7_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_7_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_8_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_8_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_9_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_9_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_10_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_10_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_11_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_11_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_12_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_12_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_13_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_13_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_14_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_14_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_15_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_15_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_16_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_16_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_17_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_17_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_18_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_18_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_19_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_19_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_20_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_20_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_21_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_21_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_22_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_22_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_23_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_23_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_24_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_24_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_25_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_25_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_26_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_26_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_27_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_28_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_28_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_28_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_29_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_29_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_30_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_30_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_31_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_31_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_32_small.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Grundsteuererklaerung_Formulare_Hessen_32_thumb.png',
	},
]
