export const FormulareHamburg = [
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_1.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_1_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_2.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_2_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_3.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_3_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_4.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_4_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_5.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_5_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_6.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_6_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_7.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_7_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_8.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_8_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_9.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_9_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_10.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_10_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_11.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_11_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_12.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_12_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_13.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_13_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_14.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_14_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_15.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_15_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_16.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_16_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_17.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_17_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_18.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_18_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_19.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_19_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_20.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_20_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_21.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_21_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_22.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_22_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_23.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_23_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_24.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_24_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_25.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_25_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_26.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_26_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_27.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_27_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_28.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_28_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_29.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_29_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_30.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_30_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_31.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_31_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_32.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_32_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_33.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_33_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_34.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_34_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_35.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_35_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_36.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_36_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_37.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_37_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_38.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_38_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_39.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_39_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_40.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_40_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_41.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_41_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_42.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_42_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_43.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_43_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_44.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_44_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_45.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_45_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_46.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_46_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_47.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_47_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_48.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_48_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_49.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_49_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_50.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hamburg/Formulare_Hamburg_50_thumb.png',
	}
]