export const FormulareBundesmodell = [
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_1.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_1_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_2.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_2_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_3.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_3_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_4.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_4_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_5.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_5_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_6.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_6_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_7.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_7_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_8.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_8_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_9.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_9_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_10.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_10_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_11.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_11_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_12.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_12_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_13.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_13_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_14.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_14_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_15.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_15_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_16.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_16_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_33.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_33_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_34.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_34_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_35.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_35_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_36.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_36_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_37.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_37_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_38.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_38_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_39.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_39_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_40.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_40_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_41.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_41_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_42.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_42_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_43.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_43_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_44.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_44_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_45.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_45_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_46.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_46_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_47.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_47_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_48.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_48_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_49.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_49_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_50.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_50_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_51.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_51_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_52.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_52_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_53.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_53_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_54.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_54_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_55.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_55_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_56.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_56_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_57.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_57_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_58.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_58_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_59.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bundesmodell/Bundesmodell_59_thumb.png',
	}
]