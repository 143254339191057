export const FormulareBW = [
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_3.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_3_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_4.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_4_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_5.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_5_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_6.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_6_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_7.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_7_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_8.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_8_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_9.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_9_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_10.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_10_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_11.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_11_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_12.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_12_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_13.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_13_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_14.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_14_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_15.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_15_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_16.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_16_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_17.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_17_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_18.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_18_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_19.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_19_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_20.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_20_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_21.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_21_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_22.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_22_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_23.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_23_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_24.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_24_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_25.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_25_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_26.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_26_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_27.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_27_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_28.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_28_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_29.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_29_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_30.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_30_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_31.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_31_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_32.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_32_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_33.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_33_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_34.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_34_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_35.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_35_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_36.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_36_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_37.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_37_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_38.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_38_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_39.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_39_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_40.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_BW/Grundsteuer_Formulare_BW_40_thumb.png',
	},
	
]