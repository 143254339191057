<template>
<div ref="widget_main" id="main_div" class="main_div" v-bind:class="{ 'main_div_im': integration_mode, 'modal-open': modal_info_is_showing }">

  <div v-if="modal_info_is_showing" class="modal-backdrop fade in"></div>
  <div
      class="modal fade"
      :class="{'in': modal_info_is_showing}"
      :style="{'display': modal_info_is_showing ? 'block' : 'none'}"
      id="modal_info"
      tabindex="-1"
      role="dialog" aria-labelledby="modal_info_title"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click.prevent="modal_info_is_showing=false" aria-label="Schließen"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="modal_info_title">{{ modal_info_title }}</h4>
        </div>
        <div class="modal-body">
          <p id="modal_info_text" style="text-align: left;" v-html="modal_info_text"></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click.prevent="modal_info_is_showing=false">OK</button>
        </div>
      </div>
    </div>
  </div>

  <CoolLightBox 
    :items="formular_items_box" 
    :index="formular_index"
    :slideshow="false"
    thumbsPosition="bottom"
    @close="formular_index = null"
    :translations="{
      previous: 'Zurück',
      next: 'Weiter',
      showThumbNails: 'Vorschau anzeigen',
      fullScreen: 'Vollbild',
      close: 'Schließen' 
    }"
  >
  </CoolLightBox>

  <div v-if="curq===qs['grste3']">
    <Grste3
      :mini_mode="true"
      title="Benötigte Daten & Unterlagen herausfinden"
      widget_name="W4"
      widget_detail="B"
      :no_pixel="true"
      header_text="Unsere Empfehlungen"
      :utm_base="utm_base"
      :ad_anzahl="ad_anzahl"
      :ad_k="ad_b_k"
      :ad_s="ad_b_s"
      :ad_m="ad_b_m"
      @on_nochmal="nochmal"
      :initial_bl="initial_bl"
      :anzeige_text="anzeige_text_b"
      :abgabefrist_datum="abgabefrist_datum"
      :abgabefrist_1="abgabefrist_1"
      :abgabefrist_2="abgabefrist_2"
      :abgabefrist_3="abgabefrist_3"
      :abgabefrist_4="abgabefrist_4"
      :abgabefrist_5="abgabefrist_5"
      :abgabefrist_6="abgabefrist_6"
      :abgabefrist_7="abgabefrist_7"
      :abgabefrist_8="abgabefrist_8"
      :abgabefrist_9="abgabefrist_9"
      :abgabefrist_10="abgabefrist_10"
      :abgabefrist_11="abgabefrist_11"
      :abgabefrist_12="abgabefrist_12"
      :abgabefrist_13="abgabefrist_13"
      :abgabefrist_14="abgabefrist_14"
      :abgabefrist_15="abgabefrist_15"
      :abgabefrist_16="abgabefrist_16"
    >
      <div class="question_back_button_box">
        <a @click.prevent="f_zurueck" class="">Zurück</a>
      </div>
    </Grste3>
  </div>
  <ul v-else class="pricing-table">
    <li class="full_box" v-bind:class="{ 'full_box_im': integration_mode }">

      <div v-if="curq===qs['wie']">
        <div style="background-color: #42c37a; color: #fff; padding: 16px; margin-bottom: 0px;">
          <h1>Alles Wichtige zur neuen Grundsteuer</h1>
        </div>
      </div>
      <div v-else-if="curq===qs['bl']">
        <div style="background-color: #42c37a; color: #fff; padding: 16px; margin-bottom: 0px;">
          <h1>Formulare ansehen</h1>
        </div>
      </div>
      <div v-else-if="curq===qs['steuer'] && !steuererklaerung_ergebnis">
        <div style="background-color: #42c37a; color: #fff; padding: 16px; margin-bottom: 0px;">
          <h1>{{ steuererklaerung_title || "Grundsteuererklärung erledigen" }}</h1>
        </div>
      </div>

      <div class="pricing-inner">
        <div class="pricing-content">

          <div v-if="curq===null">
            <p class="question_text">
            </p>
            <div class="question_button_box">
              <button @click="start" class="button">Starten</button>
            </div>
          </div>

          <div v-else-if="curq===qs['wie']">
            <p class="question_text">
              Worüber möchten Sie sich informieren?
            </p>

            <div class="question_box_buttons">
              <button @click="f_wie('formular')" class="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                </svg>
                <p>
                  Formulare
                  <br />
                  &nbsp;
                  <br />
                  &nbsp;
                </p>
              </button>
              <button @click="f_wie('steuer')" class="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 20 20" fill="currentColor">
  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
</svg>
                <p>
                  Grund&shy;steuer&shy;erklärung erledigen
                </p>
              </button>
              <button @click="f_wie('grste3')" class="button bl-button">
                <BLMapSimple></BLMapSimple>
                <p>
                  Benötigte Daten & Unterlagen herausfinden
                </p>
              </button>
              <button
                class="button"
                @click.prevent="on_rechner_click"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm1 2a1 1 0 000 2h6a1 1 0 100-2H7zm6 7a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm-3 3a1 1 0 100 2h.01a1 1 0 100-2H10zm-4 1a1 1 0 011-1h.01a1 1 0 110 2H7a1 1 0 01-1-1zm1-4a1 1 0 100 2h.01a1 1 0 100-2H7zm2 1a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm4-4a1 1 0 100 2h.01a1 1 0 100-2H13zM9 9a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zM7 8a1 1 0 000 2h.01a1 1 0 000-2H7z" clip-rule="evenodd" />
                </svg>
                <p>
                  Grundsteuer Rechner
                  <br />
                  &nbsp;
                </p>
              </button>
            </div>
          </div>

          <div v-else-if="curq===qs['formular']">
            <div class="row">
              <div class="col-sm-10 col-sm-offset-1">
                <p class="question_text">
                  Formulare {{bl_name}}
                </p>
              </div>
              <div class="col-sm-1" style="text-align: right;">
                <button @click.prevent="nochmal" class="btn btn-link">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>

            <div style="text-align: center; margin-top: 8px;">
              <div class="row">
                <div class="col col-xs-12">
                  <div v-if="bl === 9" class="alert alert-info" role="alert" style="text-align: justify;">
                    Die Grundsteuererklärung kann entweder elektronisch oder in Papier abgegeben werden.
                  </div>
                  <div v-else class="alert alert-danger" role="alert" style="text-align: justify;">
                    Bitte beachten Sie, dass die Formulare nur Informationszwecken dienen, da die Grundsteuererklärung bis auf Härtefälle <strong>elektronisch</strong> abgegeben werden muss.
                  </div>
                </div>
              </div>
            </div>

            <p style="text-align: left;">
              Vorschau:
            </p>

            <div class="images-wrapper" style="width: 100%; display: flex; flex: 1 0 auto; overflow: hidden;">
              <div
                class="image"
                v-for="(image, imageIndex) in formular_items_show"
                :key="imageIndex"
                @click="formular_index = imageIndex"
                :style="{
                  minWidth: '106px',
                  width: '106px',
                  height: '160px',
                  cursor: 'zoom-in',
                  marginLeft: '0',
                  marginRight: '8px',
                  backgroundImage: 'url(' + image.thumb + ')'
                }"
              ></div>
            </div>

            <p style="text-align: right; color: #b0b0b0; margin-bottom: 0;">
              {{formular_copy_infos_show}}
            </p>

            <div style="text-align: center; margin-top: 8px;">
              <div class="row">
                <div class="col col-xs-12">
                  <div class="alert alert-success" role="alert" style="text-align: justify;">
                     Bei einer elektronischen Abgabe per ELSTER benötigen Sie typischerweise eine Zertifikatsdatei. Bequemer ist die Beauftragung einer <strong>Kanzlei</strong> oder eine Übermittlung per <strong>Steuersoftware</strong>. Dort erhalten Sie professionelle Unterstützung bzw. ausführliche Erläuterungen und es wird kein eigenes ELSTER-Zertifikat benötigt. Die Erklärungsabgabe ist damit schnell und einfach erledigt.
                  </div>
                </div>
              </div>
            </div>

            <AdBoxes
              widget_name="W4"
              widget_detail="F"
              :anzeige_text="anzeige_text_f"
              :utm_base="utm_base"
              :ad_anzahl="ad_anzahl"
              :ad_k="ad_f_k"
              :ad_s="ad_f_s"
              :ad_m="ad_f_m"
            >
            </AdBoxes>

          </div>

          <div v-else-if="curq===qs['steuer']">
            <Steuererklaerung
              :utm_base="utm_base"
              :ad_anzahl="ad_anzahl"
              :anzeige_text_s="anzeige_text_s"
              :ad_k="ad_s_k"
              :ad_k_alt="ad_s_k_alt"
              :ad_s="ad_s_s"
              :ad_m="ad_s_m"
              @on_ergebnis="on_s_ergebnis"
              @on_nochmal="nochmal"
            >
              <div v-if="!open" class="question_back_button_box">
                <a @click.prevent="f_zurueck" class="">Zurück</a>
              </div>
            </Steuererklaerung>
          </div>
          
          <div v-else-if="curq===qs['bl']">
            <div class="row" style="margin: 0;">
              <h3 class="mini_header_small">
                In welchem Bundes&shy;land liegt das Grund&shy;stück?
              </h3>
              <div class="col-lg-3">
                <BLMap @on_bl="on_bl" :mini_mode="true"></BLMap>
              </div>
              <div class="col-lg-9">
                <h3 class="mini_header_large" style="text-align: left; margin-left: 15px;">
                  In welchem Bundes&shy;land liegt das Grund&shy;stück?
                </h3>
                <div class="row mini_row">
                  <div class="col-lg-7">
                    <select
                      type="text"
                      id="bl_select"
                      name="bl_select"
                      v-model="bl"
                      class="form-control mini_mode_select"
                    >
                      <option value="null">— Bundesland wählen —</option>
                      <option
                        v-for="option in bl_names"
                        v-bind:value="option.value"
                        v-bind:key="option.value"
                      >{{ option.text }}</option>
                    </select>
                  </div>
                  <div class="col-lg-5">
                    <button @click='on_bl()' class="button weiter_button weiter_button_mini">Weiter</button>
                  </div>
                </div>
                <p v-if="bl_error" class="question_box_error" style="margin-top: 16px;">
                  Bitte zuerst ein Bundesland auswählen.
                </p>
              </div>
            </div>
            <div v-if="!open" class="question_back_button_box">
              <a @click.prevent="f_zurueck" class="">Zurück</a>
            </div>
          </div>

          <div v-else>
            <p class="question_text">
              Es ist ein unbekannter Fehler aufgetreten, bitte versuchen Sie es nochmal.
            </p>
            <div class="question_button_box">
              <button @click="nochmal" class="button">Nochmal</button>
            </div>
          </div>

        </div>
      </div>
    </li>
  </ul>
	<img v-if="pixel_data && pixel_utm" :src='"https://widget.grundsteuer.de/pixel.php?" + pixel_data + "&utm_source=" + pixel_utm' />
  <br style="clear:left;" />
</div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'

import Grste3 from '../grste3/Grste3.vue';
import BLMap from '../grste3/BLMap.vue';
import AdBoxes from '../shared/AdBoxes.vue';
import Steuererklaerung from './Steuererklaerung.vue';
import BLMapSimple from './BLMapSimple.vue';
import {FormulareBundesmodell} from './formulare/Bundesmodell';
import {FormulareBayern} from './formulare/Bayern';
import {FormulareBW} from './formulare/BW';
import {FormulareHamburg} from './formulare/Hamburg';
import {FormulareHessen} from './formulare/Hessen';
import {FormulareNiedersachsen} from './formulare/Niedersachsen';

export default {
  name: "Hilfe",
  components: {
    CoolLightBox,
    BLMap,
    BLMapSimple,
    Grste3,
    AdBoxes,
    Steuererklaerung,
},
  props: {
    // Integration in "Rechner"
    integration_mode: Boolean,

    // Festlegen bei Start
    initial_bl: null,

    // Werbung
    utm_base: String,
    
    pixel_data: String,
    ad_anzahl: Number,

    anzeige_text_s: String,
    anzeige_text_f: String,
    anzeige_text_b: String,

    ad_s_k: String,
    ad_s_k_alt: String,
    ad_f_k: String,
    ad_b_k: String,

    ad_s_s: String,
    ad_f_s: String,
    ad_b_s: String,

    ad_s_m: String,
    ad_f_m: String,
    ad_b_m: String,
    
    abgabefrist_datum: String,

    // Abgabefristtexte pro Bundesland
    abgabefrist_1: String,
    abgabefrist_2: String,
    abgabefrist_3: String,
    abgabefrist_4: String,
    abgabefrist_5: String,
    abgabefrist_6: String,
    abgabefrist_7: String,
    abgabefrist_8: String,
    abgabefrist_9: String,
    abgabefrist_10: String,
    abgabefrist_11: String,
    abgabefrist_12: String,
    abgabefrist_13: String,
    abgabefrist_14: String,
    abgabefrist_15: String,
    abgabefrist_16: String,

    // Direkt starten mit...
    open: String,

    steuererklaerung_title: String,
  },
  mounted: function() {
    // Directly preload Adsense for Cookiebot
    var as_script = document.createElement("script");
    as_script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9660392338473353";
    as_script.crossOrigin = "anonymous";
    as_script.async = true;
    as_script.type = "text/plain";
    as_script.dataset.cookieconsent = "marketing";
    document.head.appendChild(as_script);

    this.domain = window.location.hostname;

    if (this.utm_base) {
      this.pixel_utm = this.utm_base + "W4";
    }
    else if (this.domain == "grundsteuer.de") {
      this.pixel_utm = "GS_U_C_W4";
    }
    else if (this.domain == "hebesatz.grundsteuer.de") {
      this.pixel_utm = "HS_U_C_W4";
    }
    else {
      this.pixel_utm = "GS_U_C_W4";
    }

    let scroll_to_widget = false;
    let params = new URLSearchParams(window.location.search);
    if (params.has("bl")) {
      this.bl_start = parseInt(params.get("bl"));
      if (isNaN(this.bl_start)) {
        this.bl_start = null;
      }
      else {
        scroll_to_widget = true;
      }
    }
    else if (this.initial_bl) {
      this.bl_start = this.initial_bl;
    }

    // Scroll to content content
    if (scroll_to_widget) {
      let element = this.$refs["widget_main"];

      setTimeout(function() {
        let nav_height = 0;
        let nav = document.getElementById("site-header");
        if (nav) {
          nav_height = nav.offsetHeight;
        }
        let offset = element.getBoundingClientRect().top + window.scrollY - nav_height;
        window.scrollTo({"top": offset});
      }, 500);
    }

    this.start();

    const bls = this.bl_names.map(b => b["value"]);
    if (this.bl_start && bls.includes(this.bl_start)) {
      this.bl = this.bl_start;
    }
  },
  data: function() {
    return {
      domain: null,
      pixel_utm: null,

      // Aktuelle Frage
      curq: 1,

      // Betrachtete Fragen
      qstack: [ 1 ],

      // Fragen
      qs: {
        "wie": 1,
        "bl": 2,
        "formular": 3,
        "steuer": 4,
        "grste3": 5,
      },

      // Wie helfen?
      wie: null,

      // Bundesland
      bl_start: null,
      bl: null,
      bl_error: null,
      bl_names: [
        { value: 8, text: "Baden-Württemberg" },
        { value: 9, text: "Bayern" },
        { value: 11, text: "Berlin" },
        { value: 12, text: "Brandenburg" },
        { value: 4, text: "Bremen" },
        { value: 2, text: "Hamburg" },
        { value: 6, text: "Hessen" },
        { value: 13, text: "Mecklenburg-Vorpommern" },
        { value: 3, text: "Niedersachsen" },
        { value: 5, text: "Nordrhein-Westfalen" },
        { value: 7, text: "Rheinland-Pfalz" },
        { value: 10, text: "Saarland" },
        { value: 14, text: "Sachsen" },
        { value: 15, text: "Sachsen-Anhalt" },
        { value: 1, text: "Schleswig-Holstein" },
        { value: 16, text: "Thüringen" }
      ],

      // Info-Text
      modal_info_title: '',
      modal_info_text: '',
      modal_info_is_showing: false,

      // Formulare
      formular_index: null,
      formular_items: {
        0: FormulareBundesmodell,
        9: FormulareBayern,
        8: FormulareBW,
        2: FormulareHamburg,
        6: FormulareHessen,
        3: FormulareNiedersachsen
      },
      formular_copy_infos: {
        0: "© Bundesministerium der Finanzen",
        8: "© Ministerium für Finanzen Baden-Württemberg",
        9: "© Bayerisches Staatsministerium der Finanzen und für Heimat",
        2: "© Finanzbehörde der Freien und Hansestadt Hamburg",
        6: "© Hessisches Ministerium der Finanzen",
        3: "© Niedersächsisches Finanzministerium",
      },

      // Steuererklärung fertig?
      steuererklaerung_ergebnis: false,
    };
  },
  computed: {
    formular_copy_infos_show: function() {
      if (this.bl in this.formular_copy_infos) {
        return this.formular_copy_infos[this.bl];
      }
      return this.formular_copy_infos[0];
    },

    formular_items_show: function() {
      return this.formular_items_box.slice(0, 7);
    },

    formular_items_box: function() {
      if (this.bl in this.formular_items) {
        return this.formular_items[this.bl];
      }
      return this.formular_items[0];
    },

    bl_name: function() {
      for (const blo of this.bl_names) {
        if (blo["value"] === this.bl) {
          return blo["text"];
        }
      }
      return "";
    },
  },
  watch: {
    curq: function(new_val, old_val) {
      // Speichern dass sich die Frage geändert hat
      // Aber nur, wenn es eine neuere Frage ist
      if (new_val > old_val) {
        this.qstack.push(new_val);
      }
    }
  },
  methods: {
    on_rechner_click: function() {
      this.track_event("1_R");
      setTimeout(function() {
        window.location="https://grundsteuer.de/rechner/?mtm=W4&utm_source=W4";
      }, 300)
    },

    track_event: function(step) {
      const event_label = "W4_" + step;

      if (window.ga) {
        window.ga('send', 'event', 'W4', 'Klick', event_label);
      }
      if (window._paq) {
        window._paq.push(['trackEvent', 'W4', 'Klick', event_label]);
      }
    },

    on_s_ergebnis: function() {
      this.steuererklaerung_ergebnis = true;
    },

    on_toggle_info_text: function(title, text) {
      this.modal_info_title = title;
      this.modal_info_text = text;
      this.modal_info_is_showing = true;
    },

    start: function() {
      const start_id = this.qs['wie'];
      this.qstack = [ start_id ];
      this.curq = start_id;

      // Direkt starten...
      if (this.open) {
          this.f_wie(this.open);
      }
    },

    nochmal: function() {
      this.wie = null;

      this.bl_start = null;
      this.bl = null;
      this.bl_error = null;

      this.formular_index = null;

      this.steuererklaerung_ergebnis = null;

      this.start();
    },
    
    f_zurueck: function() {
      // Gehts zurück?
      // Der aktuelle Schritt ist ebenfalls in der Liste
      if (this.qstack.length > 1) {
        // Entfernen des aktuellen
        this.qstack.pop();
        
        // Den vorherigen holen
        const last_q = this.qstack[this.qstack.length-1];
        
        // Zur Frage springen
        this.curq = last_q;
      }
    },

    f_wie: function(wie) {
      this.wie = wie;

      if (this.wie == "grste3") {
        this.track_event("1_B");
      }
      else if (this.wie == "steuer") {
        this.track_event("1_S");
      }
      else if (this.wie == "formular") {
        this.track_event("1_F");
      }

      if (this.wie == "grste3") {
        this.curq = this.qs['grste3'];
      }
      else if (this.wie == "steuer") {
        this.curq = this.qs['steuer'];
      }
      else {
        if (this.bl) {
          this.curq = this.qs[this.wie];
        }
        else {
          this.curq = this.qs['bl'];
        }
      }
    },

    on_bl: function(data) {
      if (data && "bl" in data) {
        this.bl = data.bl;
      }

      if (this.bl) {
        if (this.wie == "grste3") {
          this.track_event("2_B");
        }
        else if (this.wie == "steuer") {
          this.track_event("2_S");
        }
        else if (this.wie == "formular") {
          this.track_event("2_F");
        }

        this.bl_error = false;
        this.curq = this.qs[this.wie];
      }
      else {
        this.bl_error = true;
      }
    },
  }
};
</script>

<style scoped lang="scss">
@import '~vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

#gsde_bl_area_map a:hover path {
  fill: #f0ad4e !important;
}

.main_div {
  margin: 0 auto;
  text-align: center;
}

.main_div_im {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  text-align: center !important;
}

.full_box {
  width: 100%;
}

.full_box_im {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.question_text {
  font-size: 150%;
  font-weight: bold;
}

.pricing-table {
  margin: 0 !important;
}

.pricing-table > li {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pricing-inner::before {
  top: 0px !important;
}

.pricing-inner:hover::before {
  border: 0 !important;
  border-color: transparent !important;
}

.pricing-inner {
  //padding: 60px 40px 90px 40px;
  padding: 16px 40px 90px 40px !important;
}

@media only screen and (max-width: 767px) {
  .pricing-inner {
    padding: 16px 2px 90px 2px !important;
  }
}

.pricing-title h3 {
  text-transform: none !important;
  font-weight: normal !important;
}

.pricing-content {
  padding-top: 20px !important;
}

.pricing-content .button p {
  line-height: 1.5em;
  margin-top: 16px !important;
}

.question_box {
  margin: 16px 0 !important;
}

.question_box select {
  height: 40px !important;
  padding: 5px 15px !important;
}

.question_box .small-select {
  display: inline-block;
  width: inherit;
  width: 35%;
}

.question_box_error {
  color: #a94442 !important;
}

.question_button_box {
  margin-top: 24px !important;
}

.question_back_button_box button {
  margin-top: 8px !important;
}

.question_back_button_box button {
  background-color: transparent !important;
  border: 0 !important;
}

.question_back_button_box button:hover {
  background-color: transparent !important;
  border: 2px solid #1f2a30 !important;
  color: #1f2a30 !important;
  transition-duration: 100ms !important;
}

.question_box_buttons a p {
  text-transform: none;
}

.question_box_buttons a {
  transition-duration: 100ms !important;
  color: #000;
}

.question_box_buttons a:hover {
  color: #fff !important;
}

.question_box_buttons > button {
  text-transform: none;
}

/*.question_box_buttons > button:hover {
  border: solid rgb(66, 195, 122) 4px !important;
  background-color: #dedede !important;
  color: #000 !important;
}*/

.question_box_buttons {
  margin: 16px 0 !important;
}

.question_box_buttons button p {
  margin-top: -4px !important;
}

.question_box_buttons_small button p {
  margin-top: -24px !important;
}

.question_box_buttons button {
  height: 150px !important;
  width: 150px !important;
  margin: 6px 6px !important;
}

.question_box_buttons button svg {
  vertical-align: middle !important;
}

.question_box_buttons_small button {
  height: 150px !important;
  width: 180px !important;
  margin: 16px 16px !important;
}

.question_box_buttons_images {
  margin: 16px 0 !important;
  border-top: 0;
}

.question_box_buttons_images button {
  width: 250px !important;
  height: 100% !important;
  margin: 16px 16px !important;
  background-color: #fff !important;
  border-color: transparent !important;
  border-radius: 24px;
}

.question_box_buttons_images button:hover {
  background-color: #f1f1f1 !important;
  border-color: transparent !important;
}

.question_box_buttons_images button img {
  margin: 4px auto !important;
}

.question_box_range input {
  accent-color: #218ACB !important;
}

#baujahr_progressbar {
  height: 10px;
  width: 0;
  background-color: #218ACB;
}

#progress_bar_box {
  width: 100%;
  background-color: #218ACB;
  color: #fff;
  border: 2px solid #218ACB;
}

#progress_bar_box .progress_step {
  display: inline-block;
  padding: 0 24px 0 24px;
  height: 32px;
  //width: 33%;
}

@media only screen and (max-width: 767px) {
  #progress_bar_box .progress_step {
    width: inherit;
  }
}

#progress_bar_box .progress_step_active {
  background-color: #fff;
  color: #218ACB;
  font-weight: bold;
  padding: 0;
}

.progress_step_arrow_left {
  margin-right: 16px;
  background-color: #fff;
  float: left;
  width: 0; 
  height: 0; 
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #218ACB;
}

.progress_step_arrow_right {
  margin-left: 16px;
  background-color: #218ACB;
  float: right;
  width: 0; 
  height: 0; 
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #fff;
}

.progress_step_text {
  display: inline-block;
  height: 32px;
}

.progress_step_text_div {
  height: 32px;
  margin-top: 4px;
}

.ergebnis_box {
  border: dotted 2px black;
}

.ergebnis_title h4 {
  font-weight: bold;
  font-size: 125%;
}

table {
  //background-color: #218ACB;
  //color: #fff;
  border-radius: 16px;
  margin-bottom: -24px;
  overflow-wrap: anywhere;
}

table > thead > tr > td {
  color: #fff;
  border-color: #218ACB;
  background-color: #218ACB;
  padding: 8px !important;
}

table > tbody > tr > td {
  border-color: #ddd;
  padding: 8px !important;
}

.result_table_td {
  text-align: left;
}

.result_table_td > a {
  //color: #fff !important;
  text-decoration: underline !important;
}

.rechner-form-info-button {
  //color: #ccc;
  margin: 0;
  font-size: 20px;
  line-height: 0;
}

// TODO farben
.alert-rechner {
  background-color: #ddd;
  color: #000;
  border-color: #ddd;
}

/*.header-button {
  background-color: #fa7d19;
  transition: none !important;
  font-weight: bold;
  font-variant: normal !important;
  font-variant-caps: normal !important;
  text-transform: none;
  font-size: 17px !important;
  line-height: 24px;
}

.header-button:hover {
  border: 0;
  transition: none !important;
  border-radius: 0 !important;
}*/


.weiter_button {
  background-color: #42c37a;
  color: #fff;
}


@media only screen and (max-width: 767px) {
  .weiter_button {
    margin-right: 16px;
  }
}

.weiter_button:hover {
  border-color: #42c37a !important;
  background-color: #46CF81 !important;
}

.mini_mode_select {
  margin: 0 auto;
  margin-top: 5px;
  height: 40px;
}

.weiter_button_mini {
  width: 100%;
  background-color: #218acb;
  border: 0;
}
.weiter_button_mini:hover {
  border-color: #218acb !important;
  background-color: #1f2930 !important;
}

.mini_row {
  margin: 0;
  margin-top: 36px;
}

.mini_text {
  margin: 0 8px -16px 8px;
}

.mini_header_small {
  display: none;
}

.mini_header_large {
  display: block;
}

.question_back_button_box button {
  transition: none !important;
}

@media only screen and (max-width: 1199px) {
  .mini_mode_select {
    margin-top: 8px !important;
    margin-bottom: 16px !important;
  }

  .mini_row {
    margin-top: 0;
  }

  .mini_text {
    margin: 0 8px 4px 8px;
  }

  .mini_header_small {
    display: block;
  }

  .mini_header_large {
    display: none;
  }
}

.glyphicon-info-sign {
  cursor: pointer;
}

.alert-rechner-2 {
  border: solid 1px #ddd;
}

.question_back_button_box {
  z-index: 99999999;
  margin-top: 4px;
}

.question_back_button_box a {
  z-index: 9999999999;
  color: #5f5f5f !important;
}

.question_back_button_box button {
  transition: none !important;
  font-size: 12px;
  text-transform: none;
  width: 150px;
}

.question_back_button_box a:hover {
  cursor: pointer;
  text-decoration: underline;
}

</style>
