<template>
  <div id="gsde_hilfe_app_div">
    <Hilfe
      :initial_bl="bl ? Number(bl) : undefined"

      :utm_base="utm_base ? String(utm_base) : undefined"

      :ad_anzahl="ad_anzahl ? Number(ad_anzahl) : undefined"
      :pixel_data="pixel_data ? String(pixel_data) : undefined"

      :anzeige_text_s="anzeige_text_s ? String(anzeige_text_s) : undefined"
      :anzeige_text_f="anzeige_text_f ? String(anzeige_text_f) : undefined"
      :anzeige_text_b="anzeige_text_b ? String(anzeige_text_b) : undefined"

      :ad_s_k="ad_s_k ? String(ad_s_k) : undefined"
      :ad_s_k_alt="ad_s_k_alt ? String(ad_s_k_alt) : undefined"
      :ad_s_s="ad_s_s ? String(ad_s_s) : undefined"
      :ad_s_m="ad_s_m ? String(ad_s_m) : undefined"

      :ad_f_k="ad_f_k ? String(ad_f_k) : undefined"
      :ad_f_s="ad_f_s ? String(ad_f_s) : undefined"
      :ad_f_m="ad_f_m ? String(ad_f_m) : undefined"

      :ad_b_k="ad_b_k ? String(ad_b_k) : undefined"
      :ad_b_s="ad_b_s ? String(ad_b_s) : undefined"
      :ad_b_m="ad_b_m ? String(ad_b_m) : undefined"

      :abgabefrist_datum="abgabefrist_datum ? String(abgabefrist_datum) : undefined"

      :open="open ? String(open) : undefined"

      :steuererklaerung_title="steuererklaerung_title ? String(steuererklaerung_title) : undefined"
      
      :abgabefrist_1="abgabefrist_1 ? String(abgabefrist_1) : undefined"
      :abgabefrist_2="abgabefrist_2 ? String(abgabefrist_2) : undefined"
      :abgabefrist_3="abgabefrist_3 ? String(abgabefrist_3) : undefined"
      :abgabefrist_4="abgabefrist_4 ? String(abgabefrist_4) : undefined"
      :abgabefrist_5="abgabefrist_5 ? String(abgabefrist_5) : undefined"
      :abgabefrist_6="abgabefrist_6 ? String(abgabefrist_6) : undefined"
      :abgabefrist_7="abgabefrist_7 ? String(abgabefrist_7) : undefined"
      :abgabefrist_8="abgabefrist_8 ? String(abgabefrist_8) : undefined"
      :abgabefrist_9="abgabefrist_9 ? String(abgabefrist_9) : undefined"
      :abgabefrist_10="abgabefrist_10 ? String(abgabefrist_10) : undefined"
      :abgabefrist_11="abgabefrist_11 ? String(abgabefrist_11) : undefined"
      :abgabefrist_12="abgabefrist_12 ? String(abgabefrist_12) : undefined"
      :abgabefrist_13="abgabefrist_13 ? String(abgabefrist_13) : undefined"
      :abgabefrist_14="abgabefrist_14 ? String(abgabefrist_14) : undefined"
      :abgabefrist_15="abgabefrist_15 ? String(abgabefrist_15) : undefined"
      :abgabefrist_16="abgabefrist_16 ? String(abgabefrist_16) : undefined"
    />
  </div>
</template>

<script>
import Hilfe from './Hilfe.vue'

export default {
  name: 'AppEmbed',
  components: {
    Hilfe
  },
  props: {
    bl: null,
    utm_base: null,
    ad_anzahl: null,
    pixel_data: null,
    anzeige_text_s: null,
    anzeige_text_f: null,
    anzeige_text_b: null,
    ad_s_s: null,
    ad_s_k: null,
    ad_s_k_alt: null,
    ad_s_m: null,
    ad_f_s: null,
    ad_f_k: null,
    ad_f_m: null,
    ad_b_s: null,
    ad_b_k: null,
    ad_b_m: null,
    abgabefrist_datum: null,
    open: null,
    steuererklaerung_title: null,
    abgabefrist_1: null,
    abgabefrist_2: null,
    abgabefrist_3: null,
    abgabefrist_4: null,
    abgabefrist_5: null,
    abgabefrist_6: null,
    abgabefrist_7: null,
    abgabefrist_8: null,
    abgabefrist_9: null,
    abgabefrist_10: null,
    abgabefrist_11: null,
    abgabefrist_12: null,
    abgabefrist_13: null,
    abgabefrist_14: null,
    abgabefrist_15: null,
    abgabefrist_16: null,
  }
}
</script>

<style scoped lang="scss">
</style>
