export const FormulareBayern = [
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_1.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_1_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_2.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_2_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_3.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_3_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_4.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_4_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_5.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_5_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_6.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_6_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_7.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_7_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_8.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_8_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_9.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_9_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_10.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_10_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_11.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_11_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_12.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_12_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_13.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_13_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_14.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_14_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_15.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_15_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_16.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_16_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_17.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_17_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_18.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_18_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_19.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_19_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_20.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_20_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_21.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_21_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_22.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_22_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_23.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_23_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_24.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_24_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_25.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_25_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_26.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_26_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_27.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_27_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_28.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_28_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_29.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_29_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_30.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_30_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_31.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_31_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_32.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_32_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_33.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_33_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_34.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_34_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_35.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_35_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_36.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_36_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_37.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_37_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_38.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_38_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_39.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_39_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_40.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_40_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_41.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_41_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_42.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_42_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_43.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_43_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_44.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_44_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_45.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_45_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_46.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_46_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_47.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_47_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_48.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_48_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_49.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Bayern/Formulare_Bayern_49_thumb.png',
	}
]