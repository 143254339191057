<template>
<div>
          <div v-if="curq===null">
            <p class="question_text">
            </p>
            <div class="question_button_box">
              <button @click="start" class="button">Starten</button>
            </div>
          </div>
          
          <div v-else-if="curq===qs['ergebnis']">
            <div class="row">
              <div class="col-sm-10 col-sm-offset-1">
                <p class="question_text">
                  Ergebnis
                </p>
              </div>
              <div class="col-sm-1" style="text-align: right;">
                <button @click.prevent="nochmal" class="btn btn-link">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
            <p>
              Folgende Möglichkeiten haben Sie die Grundsteuererklärung abzugeben:
            </p>

            <div style="text-align: center; margin-top: 16px;">
              <div class="row row-no-gutters">
                <div class="col col-sm-6" style="padding-right: 4px;">
                  <div class="alert alert-rechner-2" role="alert" style="text-align: left;">
                    <strong>Selbst erledigen</strong>
                    <table style="margin-top: 0 !important; margin-bottom: 0 !important;">
                      <tr>
                        <td>
                          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" fill="rgb(66, 195, 122)" style="vertical-align: middle; margin-right: 4px;">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                          </svg>
                        </td>
                        <td>
                          Unterlagen raussuchen und Formulare selbst ausfüllen
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" fill="rgb(66, 195, 122)" style="vertical-align: middle; margin-right: 4px;">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                          </svg>
                        </td>
                        <td>
                          Keine oder nur geringe Kosten
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" fill="#a94442" style="vertical-align: middle; margin-right: 4px;">
                            <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                          </svg>
                        </td>
                        <td>
                          Höherer Aufwand
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="col col-sm-6" style="padding-left: 4px;">
                  <div class="alert alert-rechner-2" role="alert" style="text-align: left;">
                    <strong>Kanzlei beauftragen</strong>
                    <table style="margin-top: 0 !important; margin-bottom: 0 !important;">
                      <tr>
                        <td>
                          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" fill="rgb(66, 195, 122)" style="vertical-align: middle; margin-right: 4px;">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                          </svg>
                        </td>
                        <td>
                          Bequem die Grundsteuererklärung professionell erstellen lassen
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" fill="rgb(66, 195, 122)" style="vertical-align: middle; margin-right: 4px;">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                          </svg>
                        </td>
                        <td>
                          Keine Fehler machen
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" fill="#a94442" style="vertical-align: middle; margin-right: 4px;">
                            <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                          </svg>
                        </td>
                        <td>
                          Höhere Kosten
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="alert alert-success" role="alert" style="text-align: justify;">
              <span v-html="result_texts[ergebnis]"></span>
            </div>

            <AdBoxes
              widget_name="W4"
              widget_detail="S"
              :anzeige_text="anzeige_text_s"
              :utm_base="utm_base"
              :utm_ext="current_utm_ext"
              :ad_anzahl="ad_anzahl"
              :ad_k="current_ad_k"
              :ad_s="ad_s"
              :ad_m="ad_m"
            >
            </AdBoxes>
          </div>
  
          <div v-else-if="curq===qs['wichtig']">
            <p class="question_text">
              Was ist Ihnen wichtiger?
            </p>

            <div class="question_box_buttons">
              <button @click="f_wichtig(false)" class="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.736 6.979C9.208 6.193 9.696 6 10 6c.304 0 .792.193 1.264.979a1 1 0 001.715-1.029C12.279 4.784 11.232 4 10 4s-2.279.784-2.979 1.95c-.285.475-.507 1-.67 1.55H6a1 1 0 000 2h.013a9.358 9.358 0 000 1H6a1 1 0 100 2h.351c.163.55.385 1.075.67 1.55C7.721 15.216 8.768 16 10 16s2.279-.784 2.979-1.95a1 1 0 10-1.715-1.029c-.472.786-.96.979-1.264.979-.304 0-.792-.193-1.264-.979a4.265 4.265 0 01-.264-.521H10a1 1 0 100-2H8.017a7.36 7.36 0 010-1H10a1 1 0 100-2H8.472c.08-.185.167-.36.264-.521z" clip-rule="evenodd" />
</svg>
                <p>
                  Geringe Kosten
                </p>
              </button>
              <button @click="f_wichtig(true)" class="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 20 20" fill="currentColor">
  <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
</svg>
                <p>
                  Keine Fehler machen
                </p>
              </button>
            </div>

            <slot></slot>
          </div>
  
          <div v-else-if="curq===qs['elektronisch']">
            <p class="question_text">
              Haben Sie Erfahrung mit der elektronischen Erklärungsabgabe?
            </p>

            <div class="question_box_buttons">
              <button @click="f_elektronisch(false)" class="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
                </svg>
                <p>
                  Nein
                </p>
              </button>
              <button @click="f_elektronisch(true)" class="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <p>
                  Ja
                </p>
              </button>
            </div>

            <div class="question_back_button_box">
              <a @click.prevent="f_zurueck" class="">Zurück</a>
            </div>
          </div>
  
          <div v-else-if="curq===qs['besonderheiten']">
            <p class="question_text">
              Bestehen in Ihrem Fall Besonderheiten?
            </p>

            <p>
              Dazu zählen z.B. komplizierte Eigentumsverhältnisse, verschiedene Nutzungsarten oder Kernsanierungen in der Vergangenheit.
            </p>

            <div class="question_box_buttons">
              <button @click="f_besonderheiten(false)" class="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
                </svg>
                <p>
                  Nein
                </p>
              </button>
              <button @click="f_besonderheiten(true)" class="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <p>
                  Ja
                </p>
              </button>
            </div>

            <div class="question_back_button_box">
              <a @click.prevent="f_zurueck" class="">Zurück</a>
            </div>
          </div>

          <div v-else>
            <p class="question_text">
              Es ist ein unbekannter Fehler aufgetreten, bitte versuchen Sie es nochmal.
            </p>
          </div>
</div>
</template>

<script>
import AdBoxes from '../shared/AdBoxes.vue';
export default {
    name: "Steuererklaerung",
    props: {
      // Werbung
      utm_base: String,
      ad_anzahl: Number,
      ad_k: String,
      ad_k_alt: String,
      ad_s: String,
      ad_m: String,
      anzeige_text_s: String,
    },
    data: function () {
        return {
            curq: 1,
            qstack: [1],
            qs: {
                "wichtig": 1,
                "elektronisch": 2,
                "besonderheiten": 3,
                "ergebnis": 4
            },

            ergebnis: null,

            result_texts: {
              1: "Um Fehler bei der Abgabe der Grundsteuererklärung zu vermeiden, empfehlen wir die Grundsteuererklärung professionell durch eine <strong>Kanzlei</strong> erstellen zu lassen.",
              2: "Fehlende Erfahrung bei der elektronischen Abgabe der Grundsteuererklärung muss kein Hinderungsgrund sein. Probieren Sie doch einfach mal eine <strong>Steuersoftware</strong> aus. Ein ELSTER-Zertifikat ist dafür bei den meisten Anbietern gar nicht nötig. Bei Schwierigkeiten können Sie immer noch eine Kanzlei beauftragen.",
              3: "Aufgrund der Besonderheiten in Ihrem Fall, empfehlen wir eine <strong>Kanzlei</strong> mit der Erstellung der Grundsteuererklärung zu beauftragen, außer Sie trauen sich die Erklärungsabgabe selbst zu.",
              4: "Sie haben bereits Erfahrung mit der elektronischen Erklärungsabgabe und es bestehen keine Besonderheiten in Ihrem Fall. Dann spricht auch nichts dagegen, die Grundsteuererklärung selbst zu erledigen. Mehr Komfort bei der Erklärungsabgabe als ELSTER bietet eine <strong>Steuersoftware</strong>."
            }
        };
    },
    computed: {
      is_alt_result: function() {
        return this.ad_k_alt && (this.ergebnis == 4 || this.ergebnis == 2);
      },
      current_ad_k: function() {
        if (this.is_alt_result) {
          return this.ad_k_alt;
        }
        return this.ad_k;
      },
      current_utm_ext: function() {
        if (this.is_alt_result) {
          return "_ALT";
        }
        return null;
      }
    },
    watch: {
        curq: function (new_val, old_val) {
            if (new_val > old_val) {
                this.qstack.push(new_val);
            }
        }
    },
    methods: {
        track_event: function(step) {
          const event_label = "W4_" + step;

          if (window.ga) {
            window.ga('send', 'event', 'W4', 'Klick', event_label);
          }
          if (window._paq) {
            window._paq.push(['trackEvent', 'W4', 'Klick', event_label]);
          }
        },

        nochmal: function() {
          this.$emit('on_nochmal');
          this.start();
        },

        on_ergebnis: function() {
          this.$emit(
            'on_ergebnis',
            {
              "ergebnis": true
            }
          )
        },
        start: function () {
            const start_id = this.qs["wichtig"];
            this.qstack = [start_id];
            this.curq = start_id;
        },
        f_zurueck: function () {
            if (this.qstack.length > 1) {
                this.qstack.pop();
                const last_q = this.qstack[this.qstack.length - 1];
                this.curq = last_q;
            }
        },
        f_wichtig: function (ergebnis) {

          if (ergebnis) {
            this.track_event("3_S_1");
            this.ergebnis = 1;
            this.on_ergebnis();
            this.curq = this.qs["ergebnis"];
          }
          else {
            this.track_event("3_S_2");
            this.curq = this.qs["elektronisch"];
          }
        },
        f_elektronisch: function (ergebnis) {
          if (!ergebnis) {
            this.track_event("4_S_1");
            this.ergebnis = 2;
            this.on_ergebnis();
            this.curq = this.qs["ergebnis"];
          }
          else {
            this.track_event("4_S_2");
            this.curq = this.qs["besonderheiten"];
          }
        },
        f_besonderheiten: function (ergebnis) {
          if (ergebnis) {
            this.track_event("5_S_1");
            this.ergebnis = 3;
          }
          else {
            this.track_event("5_S_2");
            this.ergebnis = 4;
          }
          this.on_ergebnis();
          this.curq = this.qs["ergebnis"];
        }
    },
    components: { AdBoxes }
};
</script>

<style scoped lang="scss">
#gsde_bl_area_map a:hover path {
  fill: #f0ad4e !important;
}

.main_div {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.main_div_im {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  text-align: center !important;
}

.full_box {
  width: 100%;
}

.full_box_im {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.question_text {
  font-size: 150%;
  font-weight: bold;
}

.pricing-table {
  margin: 0 !important;
}

.pricing-table > li {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pricing-inner::before {
  top: 0px !important;
}

.pricing-inner:hover::before {
  border: 0 !important;
  border-color: transparent !important;
}

.pricing-inner {
  //padding: 60px 40px 90px 40px;
  padding: 16px 40px 90px 40px !important;
}

@media only screen and (max-width: 767px) {
  .pricing-inner {
    padding: 16px 2px 90px 2px !important;
  }
}

.pricing-title h3 {
  text-transform: none !important;
  font-weight: normal !important;
}

.pricing-content {
  padding-top: 20px !important;
}

.question_box {
  margin: 16px 0 !important;
}

.question_box select {
  height: 40px !important;
  padding: 5px 15px !important;
}

.question_box .small-select {
  display: inline-block;
  width: inherit;
  width: 35%;
}

.question_box_error {
  color: #a94442 !important;
}

.question_button_box {
  margin-top: 24px !important;
}

.question_back_button_box button {
  margin-top: 8px !important;
}

.question_back_button_box button {
  background-color: transparent !important;
  border: 0 !important;
}

.question_back_button_box button:hover {
  background-color: transparent !important;
  border: 2px solid #1f2a30 !important;
  color: #1f2a30 !important;
  transition-duration: 100ms !important;
}

.question_box_buttons {
  margin: 16px 0 !important;
}

.question_box_buttons button p {
  margin-top: -4px !important;
}

.question_box_buttons_small button p {
  margin-top: -24px !important;
}

.question_box_buttons button {
  height: 180px !important;
  width: 200px !important;
  margin: 16px 16px !important;
}

.question_box_buttons button svg {
  vertical-align: middle !important;
}

.question_box_buttons_small button {
  height: 150px !important;
  width: 180px !important;
  margin: 16px 16px !important;
}

.question_box_buttons_images {
  margin: 16px 0 !important;
  border-top: 0;
}

.question_box_buttons_images button {
  width: 250px !important;
  height: 100% !important;
  margin: 16px 16px !important;
  background-color: #fff !important;
  border-color: transparent !important;
  border-radius: 24px;
}

.question_box_buttons_images button:hover {
  background-color: #f1f1f1 !important;
  border-color: transparent !important;
}

.question_box_buttons_images button img {
  margin: 4px auto !important;
}

.question_box_range input {
  accent-color: #218ACB !important;
}

#baujahr_progressbar {
  height: 10px;
  width: 0;
  background-color: #218ACB;
}

#progress_bar_box {
  width: 100%;
  background-color: #218ACB;
  color: #fff;
  border: 2px solid #218ACB;
}

#progress_bar_box .progress_step {
  display: inline-block;
  padding: 0 24px 0 24px;
  height: 32px;
  //width: 33%;
}

@media only screen and (max-width: 767px) {
  #progress_bar_box .progress_step {
    width: inherit;
  }
}

#progress_bar_box .progress_step_active {
  background-color: #fff;
  color: #218ACB;
  font-weight: bold;
  padding: 0;
}

.progress_step_arrow_left {
  margin-right: 16px;
  background-color: #fff;
  float: left;
  width: 0; 
  height: 0; 
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #218ACB;
}

.progress_step_arrow_right {
  margin-left: 16px;
  background-color: #218ACB;
  float: right;
  width: 0; 
  height: 0; 
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #fff;
}

.progress_step_text {
  display: inline-block;
  height: 32px;
}

.progress_step_text_div {
  height: 32px;
  margin-top: 4px;
}

.ergebnis_box {
  border: dotted 2px black;
}

.ergebnis_title h4 {
  font-weight: bold;
  font-size: 125%;
}

table {
  //background-color: #218ACB;
  //color: #fff;
  border-radius: 16px;
  margin-bottom: -24px;
  overflow-wrap: anywhere;
}

table > thead > tr > td {
  color: #fff;
  border-color: #218ACB;
  background-color: #218ACB;
  padding: 8px !important;
}

table > tbody > tr > td {
  border-color: #ddd;
  padding: 8px !important;
}

.result_table_td {
  text-align: left;
}

.result_table_td > a {
  //color: #fff !important;
  text-decoration: underline !important;
}

.rechner-form-info-button {
  //color: #ccc;
  margin: 0;
  font-size: 20px;
  line-height: 0;
}

// TODO farben
.alert-rechner {
  background-color: #ddd;
  color: #000;
  border-color: #ddd;
}

.alert-rechner-2 {
  border: solid 1px #ddd;
}

.alert-rechner > ul > li {
  padding: 0;
}

.alert-rechner-2 > ul > li {
  padding: 0;
}

/*.header-button {
  background-color: #fa7d19;
  transition: none !important;
  font-weight: bold;
  font-variant: normal !important;
  font-variant-caps: normal !important;
  text-transform: none;
  font-size: 17px !important;
  line-height: 24px;
}

.header-button:hover {
  border: 0;
  transition: none !important;
  border-radius: 0 !important;
}*/


.weiter_button {
  background-color: #42c37a;
  color: #fff;
}


@media only screen and (max-width: 767px) {
  .weiter_button {
    margin-right: 16px;
  }
}

.weiter_button:hover {
  border-color: #42c37a !important;
  background-color: #46CF81 !important;
}

.mini_mode_select {
  margin: 0 auto;
  margin-top: 5px;
  height: 40px;
}

.weiter_button_mini {
  width: 100%;
  background-color: #218acb;
  border: 0;
}
.weiter_button_mini:hover {
  border-color: #218acb !important;
  background-color: #1f2930 !important;
}

.mini_row {
  margin: 0;
  margin-top: 36px;
}

.mini_text {
  margin: 0 8px -16px 8px;
}

.mini_header_small {
  display: none;
}

.mini_header_large {
  display: block;
}

@media only screen and (max-width: 1199px) {
  .mini_mode_select {
    margin-top: 8px !important;
    margin-bottom: 16px !important;
  }

  .mini_row {
    margin-top: 0;
  }

  .mini_text {
    margin: 0 8px 4px 8px;
  }

  .mini_header_small {
    display: block;
  }

  .mini_header_large {
    display: none;
  }
}

.glyphicon-info-sign {
  cursor: pointer;
}

.question_box_buttons > button {
  text-transform: none;
}

.question_back_button_box {
  z-index: 99999999;
  margin-top: 4px;
}

.question_back_button_box a {
  z-index: 9999999999;
  color: #5f5f5f !important;
}

.question_back_button_box button {
  transition: none !important;
  font-size: 12px;
  text-transform: none;
  width: 150px;
}

.question_back_button_box a:hover {
  cursor: pointer;
  text-decoration: underline;
}

</style>
