export const FormulareNiedersachsen = [
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_19.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_19_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_20.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_20_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_21.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_21_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_22.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_22_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_23.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_23_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_24.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_24_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_25.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_25_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_26.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_26_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_27.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_27_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_28.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_28_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_29.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_29_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_30.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_30_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_31.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_31_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_32.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_32_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_33.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_33_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_34.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_34_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_35.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_35_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_36.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_36_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_37.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_37_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_38.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_38_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_39.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_39_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_40.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_40_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_41.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_41_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_43.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_43_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_44.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_44_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_45.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_45_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_46.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_46_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_47.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_47_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_48.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_48_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_49.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_49_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_50.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_50_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_51.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_51_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_52.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_52_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_53.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_53_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_54.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_54_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_55.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_55_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_56.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_56_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_57.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_57_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_58.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_58_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_59.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_59_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_60.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_60_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_61.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_61_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_62.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_62_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_63.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_63_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_64.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_64_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_65.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_65_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_66.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_66_thumb.png',
	},
	{
		src: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_67.png',
		thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Niedersachsen/Formular_Niedersachsen_67_thumb.png',
	}
]